import { gql } from '@apollo/client';
import { ORDER_FIELDS } from '../fragments/order';

const SALES_ORDERS = gql`
  ${ ORDER_FIELDS }
  query GetOrders (
    $first: Int,
    $last: Int,
    $before: String,
    $after: String,
    $where: RootQueryToOrderConnectionWhereArgs,
    ) {
    orders(first: $first, last: $last, before: $before, after: $after, where: $where) {
      edges {
        cursor
        node {
          ...OrderFields
          salesAttributes {
            showroomSalesAgent
            telesalesAgent {
              email
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }  
`;

export default SALES_ORDERS;