import { gql } from '@apollo/client';

const GET_COUPON = gql`
  query coupon (
  $id: ID!,
  $idType: CouponIdTypeEnum
  ) {
    coupon(
    id: $id,
    idType: $idType
    ) {
        amount
        code
        databaseId
        date
        dateExpiry
        description
    }
  }
`;

export default GET_COUPON;