import { gql } from '@apollo/client';

export const ORDER_FIELDS = gql`
  fragment OrderFields on Order {
    databaseId
    orderNumber
    orderKey
    discountTax(format: RAW)
    discountTotal(format: RAW)
    shippingTax(format: RAW)
    shippingTotal(format: RAW)
    totalTax(format: RAW)
    total(format: RAW)
    createdVia
    date
    status
    taxLines {
      nodes {
        taxTotal
        shippingTaxTotal
      }
    }
    couponLines {
      nodes {
        code
        discount
        coupon {
          code
          amount
          description
        }
      }
    }
    lineItems {
      nodes {
        databaseId
        quantity
        productId
        total
        totalTax
        product {
          node {
            ... on SimpleProduct {
              id
              databaseId
              name
              price
              sku
            }
          }
        }
      }
    }
    billing {
      address1
      address2
      city
      company
      email
      firstName
      lastName
      phone
      postcode
      state
      country
    }
    shipping {
      address1
      address2
      city
      company
      firstName
      lastName
      postcode
      state
      country
    }
    shippingLines {
      nodes {
        databaseId
        orderId
        total
        methodTitle
      }
    }
  }
`;