import React from 'react';
import {
  ApolloClient,
  ApolloProvider,
  ApolloLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { onError } from '@apollo/client/link/error';
import cache from '../cache';
import { typeDefs } from '../typeDefs/typeDefs';

const httpLink = new BatchHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URL,
  credentials: 'include'
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if( graphQLErrors ){ 
    graphQLErrors.map(({ message, path }) => (
      console.error(`[GraphQL error]: Message: ${ message }, Path: ${ path }`)
    ))
  }

  if(networkError) {
    console.error('Network Error:', networkError)
  }
})

// export const middleware = new ApolloLink( ( operation, forward ) => {
  const authLink = setContext((_, { headers }) => {
    const token = JSON.parse(sessionStorage.getItem('userObj'));

    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${ token.login.authToken }` : null,
      }
    }
  });

// 	return forward( operation );
// } );

export const afterware = new ApolloLink( ( operation, forward ) => {

	return forward( operation ).map( response => {
		/**
		 * Check for session header and update session in local storage accordingly.
		 */
		const context = operation.getContext();
		const { response: { headers } }  = context;
		const session = headers.get( 'woocommerce-session' );

		if ( session ) {
			if ( sessionStorage.getItem( 'woo-session' ) !== session ) {
				sessionStorage.setItem( 'woo-session', headers.get( 'woocommerce-session' ) );
			}
		}

		return response;

	} );
} );

const client = new ApolloClient({
  link: errorLink.concat(authLink.concat(httpLink)),
  cache: cache,
  typeDefs: typeDefs,
  connectToDevTools: true,
  clientState: {},
});

const Apollo = ({ children }) => {
  return (
      <ApolloProvider client={ client }>
          { children }
      </ApolloProvider>
  )
}

export default Apollo