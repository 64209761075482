import { gql } from '@apollo/client';

/**
 * GraphQL categories and products query.
 */
const PRODUCTS_QUERY = gql`
	query ProductsQuery {
	  tonal:products( first: 2, where: { sku: "T00001-1", orderby: { field: PRICE, order: ASC } } ) {
	    products:nodes {
	      ... on SimpleProduct {
	        id
			databaseId
			name
	        price
			sku
	      }
	    }
	  }
	  warranties:products( first: 2, where: { category: "warranty", orderby: { field: PRICE, order: ASC } } ) {
	    products:nodes {
	      ... on SimpleProduct {
	        id
			databaseId
			name
	        price
			sku
	      }
	    }
	  }
}`;

export default PRODUCTS_QUERY;
