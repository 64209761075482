import React from 'react';
import { Grid } from '@mui/material';
import { Paper } from '@mui/material';
import { Typography } from '@mui/material';

const styles = {
  name: {
    fontSize: '1rem',
    fontWeight: 500,
  },
  cell: {
    padding: (theme) => theme.spacing(8)
  },
  paper: {
    padding: (theme) => theme.spacing(4)
  }
}

const AddressField = (field) => {

  return (
      <Typography variant="body2" component="p">
          <span sx={ { ...styles.name } }>{field.address.name.full}</span><br/>
          {field.address.address.line1}<br/>
          {( field.address.address.line2 ) ? field.address.address.line2 : '' }
          {( field.address.address.line2 ) ? <br/> : '' }
          {field.address.address.city}, {field.address.address.state} {field.address.address.zipcode}<br/>
          {(field.address.email) ? field.address.email : ''}
          {(field.address.email) ? <br/> : ''}
          {(field.address.phone_number) ? field.address.phone_number : ''}
      </Typography>
  )  
}

const Addresses = ( props ) => {
  const { addresses } = props;

  return (
      <Paper sx={ { ...styles.paper } } elevation={ 3 }>
          <Grid container spacing={ 3 }>
              <Grid item sx={ { ...styles.cell } } xs={ 12 } sm={ 6 }>
                  <Typography variant="h5" component="h2">
                      Billing
                  </Typography>
                  <AddressField address={ addresses.billing } />
              </Grid>
              <Grid item sx={ { ...styles.cell } } xs={ 12 } sm={ 6 }>
                  <Typography variant="h5" component="h2">
                      Shipping
                  </Typography>
                  <AddressField address={ addresses.shipping } />
              </Grid>
          </Grid>
      </Paper>
  )
}

export default Addresses;