import React from 'react';
import { Box } from '@mui/material';
import { Container } from '@mui/material';
import { Link } from '@mui/material';
import { Typography } from '@mui/material';

const Footer = () => {
  const styles = {
    footer: {
      padding: '24px 16px',
      marginTop: 'auto',
      backgroundColor: '#424242',
    }
  }
  
  return (
      <Box component='footer' sx={ { ...styles.footer } }>
          <Container maxWidth="sm">
              <Typography variant="body2" color="textSecondary">
                  {'Copyright © '}
                  <Link color="inherit" href="https://tonal.com/">
                      Tonal
                  </Link>{' '}
                  {new Date().getFullYear()}
                  {'.'}
              </Typography>
          </Container>
      </Box>
  );
}

export default Footer;