import React, { useContext, useState } from 'react';
import parse from 'html-react-parser';
import { produce } from 'immer';
import { Button, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Card } from '@mui/material';
import { CardActions } from '@mui/material';
import { CardContent } from '@mui/material';
import { Grid } from '@mui/material';
import { Typography } from '@mui/material';

import { AppContext } from '../context/AppContext';

const styles = {
  card: {
    padding: (theme) => theme.spacing(2),
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: (theme) => theme.palette.primary.main,
    marginLeft: 'auto',
    marginRight: 'auto',
    borderRadius: '4px',
    marginTop: '12px',
    marginBottom: '12px',
  },
  cardContent: {
    flexGrow: 1,
  },
  cardActions: {
    justifyContent: 'center',
    flexDirection: 'column',
  },
  title: {
    textAlign: 'center',
  },
  selectedTitle: {
    color: (theme) => theme.palette.secondary.main
  },
  grid: {
    paddingBottom: (theme) => theme.spacing(2),
  },
  productGridItem: {
      flexGrow: 1,
      flexBasis: '50%',
  },
  buttonGroup: {
    marginTop: '1rem',
  }
}

const hasLineItem = (ord, prod) => { return ord.lineItems?.nodes?.find( lineItem => lineItem.product.node.name.includes(prod) ) }

const ProductCards = ( { order, setOrder, productType, products, warranties } ) => {
    const [ selectedWarranty, setSelectedWarranty ] = useState('none');

    const foundTonalLineItem = hasLineItem(order, productType)

    const removeWarranty = () => {
        setOrder( 
            produce( ( draftOrder ) => {
                const nodes = draftOrder.lineItems.nodes
                draftOrder.lineItems.nodes = nodes.filter( node => node.product.node.name.includes('warranty') === false )
            } )
        )
     }

     const handleClickAddLineItem = ( productId, findTonal = true ) => {
        if ( productId === null ) {
            return
        }

        if (productId === 'none') {
            removeWarranty()
            setSelectedWarranty(productId)
            return;
        }  

        let product = null
        if (findTonal) { 
            product = products.find( prod => prod.databaseId == productId )
        } else {
            removeWarranty()
            setSelectedWarranty(productId)
            product = warranties.find( warranty => warranty.databaseId == productId );
        }
        
        setOrder(
            produce( ( draftOrder ) => {
                const lineItems = draftOrder.lineItems
                lineItems.nodes.push( {
                    quantity: 1,
                    product: {
                        node: {
                            name: product.name,
                            databaseId: product.databaseId,
                            price: product.price,
                            image: product.image,
                        }
                    }
                } )
            } )
        )  
     }
     
     const selectedProduct = order.lineItems?.nodes?.find( lineItem => lineItem.product.node.name.includes( productType ) )

    return (
        <>
            {
                foundTonalLineItem && order.databaseId ? 
                    <Grid item sx={ { ...styles.productGridItem } }>
                        <Card sx={ { ...styles.card } }>
                            <CardContent>
                                <Typography gutterBottom sx={ { ...styles.title } } variant="h6" component="h1">
                                    { parse(foundTonalLineItem.product.node.name) }
                                </Typography>
                                <Typography gutterBottom sx={ { ...styles.title } } variant="h6" component="h2">
                                    { foundTonalLineItem.product.node.price }
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                :
                    products.map( product => {

                        return(
                            <Grid key={ product.databaseId } item xs={ 12 } sm={ 6 }>
                                <Card sx={ { ...styles.card } }>
                                    <CardContent sx={ (selectedProduct && selectedProduct.product.node.databaseId == product.databaseId) ? { ...styles.selectedTitle } : {} }>
                                        <Typography gutterBottom sx={ { ...styles.title } } variant="h6" component="h1">
                                            { parse(product.name) }
                                        </Typography>
                                        <Typography gutterBottom sx={ { ...styles.title } } variant="h6" component="h2">
                                            { product.price }
                                        </Typography>
                                        <CardActions sx={ { ...styles.cardActions } }>
                                            <Button disabled={ (undefined !== selectedProduct) } onClick={ () => { handleClickAddLineItem( product.databaseId ) } } variant="outlined" color="secondary">Add</Button>
                                            {
                                                (undefined !== selectedProduct && selectedProduct.product.node.databaseId == product.databaseId) ?
                                                    <ToggleButtonGroup sx={ { ...styles.buttonGroup } } orientation='vertical' aria-label='vertical button group' exclusive value={ selectedWarranty } onChange={ (event, newValue) => { handleClickAddLineItem(newValue, false) } }>
                                                        <ToggleButton value="none" variant="outlined" color="secondary">No Extended Warranty</ToggleButton>
                                                        {
                                                            warranties.map( warrant => {
                                                                return(
                                                                    <ToggleButton key={ warrant.databaseId } value={ warrant.databaseId } variant="outlined" color="secondary">Add {warrant.name} {warrant.price}</ToggleButton>
                                                                )
                                                            })
                                                        }
                                                    </ToggleButtonGroup>
                                                : ''
                                            }
                                        </CardActions>
                                    </CardContent>
                                </Card>
                            </Grid>
                        )
                    })
            }      
        </>
    )
}

const ProductSelect = () => {
  const { products, order, setOrder } = useContext(AppContext);

  return (
      <>
          {
                products ?
                    <Grid sx={ { ...styles.grid } } container spacing={ 2 }>
                        <ProductCards order={ order } products={ products.tonal.products } productType="Tonal" setOrder={ setOrder } warranties={ products.warranties.products } />  
                    </Grid>
                : ''
          }
      </>
    )
}

export default ProductSelect;
