import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import PRODUCTS_QUERY from '../queries/products';
import LOCATIONS_QUERY from '../queries/locations';
import GET_COUPON from '../queries/get-coupon';
import CREATE_ORDER from '../mutations/create-order'
import { isObject } from '../functions/objects';
import AddressSchema from '../schema/yup/AddressSchema';
import { v4 } from 'uuid';
import { Backdrop } from '@mui/material';
import { CircularProgress } from '@mui/material';
import { Snackbar } from '@mui/material';
import { Alert } from '@mui/material';
import RURAL_ZIP_CODES from '../data/shipping-zones';
import { autoCoupons } from '../data/auto-coupons';
import { calculateShippingTotal } from '../functions/order';
import { async } from 'q';

export const AppContext = React.createContext([
  {},
  () => {},
]);

const styles = { 
  circularProgress: {
    color: '#A4DE30',
    width:  '50px',
    height: '50px',
  },
  backdrop: {
    zIndex: (theme) => theme.zIndex.drawer + 3,
  },
}

const processStorageItem = (key, value) => {
  if ( value ) {
    const storageValue = (isObject(value)) ? JSON.stringify(value) : value
    sessionStorage.setItem(key, storageValue);
  } else {
    sessionStorage.removeItem(key);
  }
};

const setInitialStateFromStorageItem = (key) => {
  const item = sessionStorage.getItem(key);
  return ( item ) ? JSON.parse(item) : null;
}

const orderObj = {
  databaseId: 0,
  lineItems: {
    nodes: []
  },
  billing: {
    firstName: '',
    lastName: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    postcode: '',
    phone: '',
    email: '',
    country: 'US',
  },
  shipping: {
    firstName: '',
    lastName: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    postcode: '',
    country: 'US',
  }
}

export const addressObj = {
  firstName: 'First Name',
  lastName: 'Last Name',
  address1: 'Address One',
  address2: 'Address Two',
  city: 'City',
  state: 'State',
  postcode: 'Zip Code',
  phone: 'Phone',
  email: 'Email',
};

const hasProducts = ( orderToValidate ) => {
  const tonal = orderToValidate.lineItems.nodes?.find( lineItem => lineItem?.product?.node?.name.includes( 'Tonal' ) )
  return undefined !== tonal?.product.node.databaseId
}

const hasAddresses = ( orderToValidate ) => {
  const validBilling = AddressSchema.isValidSync( { ...orderToValidate.billing, addressFormType: 'billing' } )
  const validShipping = AddressSchema.isValidSync( { ...orderToValidate.shipping, addressFormType: 'shipping' } )
  return validBilling && validShipping
}

export const AppProvider = (props) => {
  const { data: couponData } = useQuery(GET_COUPON, { variables: { id: autoCoupons, idType: 'CODE' } })
  const possibleCoupon = couponData?.coupon
  const [ createOrder, { loading: createOrderLoading } ]  = useMutation(CREATE_ORDER, { skip: !couponData })
  const [ requestError, setRequestError ] = useState(null);
  const [ open, setOpen ] = useState(false);
  const [ disableTelesales, setDisableTelesales ] = useState(false);
  const [ openBackdrop, setOpenBackdrop ] = useState(false);
  const [ addresses, setAddresses ] = useState(() => {
    return setInitialStateFromStorageItem('woo-bs-addresses');
  });
  useEffect(() => {
    processStorageItem('woo-bs-addresses', addresses);
  }, [ addresses ]);

  const [ salesLocation, setSalesLocation ] = useState('')

  useEffect(() => {
    processStorageItem('woo-bs-selected-salesLocation', salesLocation)
  }, [ salesLocation ])

  const [ order, setOrder ] = useState(orderObj);
  useEffect(() => {
    processStorageItem('woo-bs-order', order);

  }, [ order ]);

  const [ orderReady, setOrderReady ] = useState(false);
  useEffect(() => {
    setOrderReady(
      order.databaseId == 0
      && hasProducts( order )
      && hasAddresses( order )
    )
  }, [ order ])

  useEffect(() => {
      setOpenBackdrop(createOrderLoading)
  }, [ createOrderLoading ]);

  useEffect(() => {
    const shippingTotal = calculateShippingTotal( order.shipping.postcode.trim(), order.shipping.state )

    if( !(orderReady && 0 === order.databaseId) ) {
      return;
    }

    const { lineItems, databaseId, ...orderToSend } = order
    const inputLineItems = lineItems.nodes.map((item) => {
      return {
        productId: item.product.node.databaseId,
        name: item.product.node.name,
        total: item.product.node.price,
        quantity: 1,
      }
    })

    const input = {
      ...orderToSend,
      lineItems: inputLineItems,
      clientMutationId: v4(),
      isPaid: false,
      shippingLines: [
        {
          methodId: 'flat_rate',
          methodTitle: 'Delivery & Installation',
          total: shippingTotal,
        },
      ],
    }

    if ( salesLocation ) {
      input.salesLocation = salesLocation
    }

    if ( possibleCoupon ) {
      const today = new Date()
      const created = new Date(possibleCoupon.date)
      const expiry = new Date(possibleCoupon.dateExpiry)
      
      if ( today.getTime() >> created.getTime() && today.getTime() << expiry.getTime() ) {
        input.coupons = possibleCoupon.code
      }
    }

    createOrder({
      variables: {
        input
      }
    })
    .then((result) => { setOrder( result.data.createOrder.order ) })
    .catch((err) => setRequestError(err.message))
    .finally(setOpenBackdrop(false))
  }, [ order, orderReady ])

  const [ checkoutId, setCheckoutId ] = useState(() => {
    return setInitialStateFromStorageItem('woo-bs-checkoutId');
  });
  useEffect(() => {
    processStorageItem('woo-bs-checkoutId', checkoutId);
  }, [ checkoutId ]);

  const [ telesales, setTelesales ] = useState(() => {
    return setInitialStateFromStorageItem('woo-bs-telesales');
  });
  useEffect(() => {
    processStorageItem('woo-bs-telesales', telesales);
  }, [ telesales ]);

  useEffect(() => {
    setOpen(null !== requestError)
  }, [ requestError ])

  const [ products, setProducts ] = useState(() => {
    return setInitialStateFromStorageItem('woo-bs-products')
  })

  useEffect(() => {
    processStorageItem('woo-bs-products', products)
  }, [ products ])

  const { loading: productsLoading } = useQuery(PRODUCTS_QUERY, {
    onCompleted: ( queriedProducts => { setProducts(queriedProducts) }) 
  });

  const [ salesLocations, setSalesLocations ] = useState(() => {
    return setInitialStateFromStorageItem('woo-bs-salesLocations')
  })

  useEffect(() => {
    processStorageItem('woo-bs-salesLocations', salesLocations)
  }, [ salesLocations ])

  const { loading: locationsLoading } = useQuery(LOCATIONS_QUERY, {
    onCompleted: ( queriedLocations => { setSalesLocations(queriedLocations) }) 
  });
  
  const handleClickClose = () => {
    setOpen(false);
  }

  return (
      <AppContext.Provider value={ {
      order, setOrder, 
      addresses, setAddresses, 
      checkoutId, setCheckoutId,
      telesales, setTelesales,
      requestError, setRequestError,
      products, productsLoading,
      salesLocations, locationsLoading,
      salesLocation, setSalesLocation,
      orderReady, setOpenBackdrop, nullOrder: orderObj,
      disableTelesales, setDisableTelesales,
      salesLocations, locationsLoading,
      salesLocation, setSalesLocation,
    } }>
          <>
              <Backdrop open={ openBackdrop } sx={ { ...styles.backdrop } }>
                  <CircularProgress color="inherit" sx={ { ...styles.circularProgress } } />
              </Backdrop>
              <Snackbar
                open={ open }
                onClose={ handleClickClose }
                anchorOrigin={ { vertical: 'top', horizontal: 'left' } }
              >
                  <Alert severity="error" onClose={ handleClickClose }>{requestError}</Alert>
              </Snackbar>
              { props.children }
          </>
      </AppContext.Provider>
  );
}
