import React, { useContext, useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { Box } from '@mui/material';
import { Container } from '@mui/material';
import { Grid } from '@mui/material';
import { FormControlLabel } from '@mui/material';
import { Switch } from '@mui/material';
import { TextField } from '@mui/material';
import { Button } from '@mui/material';
import { Dialog } from '@mui/material';
import { DialogActions } from '@mui/material';
import { DialogContent } from '@mui/material';
import { DialogContentText } from '@mui/material';
import { DialogTitle } from '@mui/material';
import { Formik, Form, useFormikContext } from 'formik';
import * as Yup from 'yup';
import InputMask from 'react-input-mask';
import { v4 } from 'uuid';
import { useMutation } from '@apollo/client';

import UPDATE_ORDER from '../mutations/update-order';

import StateSelect from '../components/state-select';
import GoogleAutocomplete from '../components/google-autocomplete'

import { AppContext, addressObj } from '../context/AppContext';
import { placeToAddress } from '../functions/geo';
import AddressSchema from '../schema/yup/AddressSchema';
import { calculateShippingTotal } from '../functions/order';

const styles = {
  grid: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  button: {
    margin: (theme) => theme.spacing(3, 0, 2),
    textTransform: 'uppercase',
    backgroundColor: (theme) => theme.palette.secondary.main,
    color: (theme) => theme.palette.primary.main,
  },
  addressBox: {
    margin: (theme) => theme.spacing(2, 0, 0),
    borderTopStyle: 'solid',
    borderTopColor: (theme) => theme.palette.secondary.main,
    borderTopWidth: 2,
    padding: (theme) => theme.spacing(4, 0, 0)
  },
  title: {
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  pageTitle: {
    padding: (theme) => theme.spacing(3, 0, 0),
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  backdrop: {
    zIndex: (theme) => theme.zIndex.drawer + 2,
  },
  overrides: {
    MuiInputBase: {
      input: {
        text: {
          color: 'white',
        }
      }
    },
  }
}

const address_fields =[ 'address1', 'city', 'state', 'postcode' ];

const AutoUpdateAddress = ( { selectedAddress } ) => {
  const formikProps = useFormikContext();
  useEffect(() => {
    if(Object.keys(selectedAddress).length > 0) {
      const formatted_address = placeToAddress( selectedAddress )
      address_fields.forEach(field => {
        switch (field) {
          case 'address1':
            formikProps.setFieldValue(field, formatted_address?.street_number + ' ' + formatted_address?.street_name)
            break;
        
          case 'city':
            formikProps.setFieldValue(field, formatted_address?.city)
            break;
        
          case 'state':
            formikProps.setFieldValue(field, formatted_address?.state)
            break;
        
          case 'postcode':
            formikProps.setFieldValue(field, formatted_address?.postal_code)
            break;
        
          default:
            break;
        }        
      })
    }
  }, [ selectedAddress ])
  return null;
}

const AddressFieldEdit = ( { addressType, setShowEdit, showAddressEditButton, setShowAddressEditButton } ) => {
  const [ updateOrder ] = useMutation(UPDATE_ORDER);
  const { order, setOrder, setRequestError } = useContext(AppContext);
  const [ initialValues, setInitialValues ] = useState(null);
  const [ addressFormValues, setAddressFormValues ] = useState({});
  const [ addressValues, setAddressValues ] = useState({});
  const [ shippingChecked, setShippingChecked ] = useState(true)
  const [ confirmCustomerInfo, setConfirmCustomerInfo ] = useState(false);

  const handleConfirmClose = () => {
    setConfirmCustomerInfo(false)
  }
  
  const handleConfirmContinue = () => {

    if( order.databaseId ) {
      const { addressFormType, __typename, ...newAddress } = addressFormValues
      const input = {
        clientMutationId: v4(),
        orderId: Number.parseInt(order.databaseId),
      }
      
      input[ `${ addressFormType }` ] = newAddress
      
      if ('shipping' == addressFormType) {
        const newShippingTotal = calculateShippingTotal( newAddress.postcode.trim(), newAddress.state )
        if ( order.shippingTotal != newShippingTotal ) {
          input[ 'shippingLines' ] = [ {
            id: order.shippingLines.nodes[ 0 ].databaseId,
            total: newShippingTotal,
            methodId: 'flat_rate',
            methodTitle: 'Delivery & Installation',
          } ]
        }
      }

      updateOrder({
        variables: {
          input,
        },
      })
      .then((result) => {
        setOrder( result.data.updateOrder.order )
      } )
      .catch((err) => {
        setRequestError(err.message)
      })
      .finally(() => {
        setConfirmCustomerInfo(true)
        setShowEdit(false)
      })
      return;
    }
 
    const { addressFormType, ...newAddress } = addressFormValues
    const addressUpdate = { ...order }
    addressUpdate[ `${ addressFormType }` ] = newAddress
    if(showAddressEditButton) {
      const { email, phone, ...shipping } = newAddress
      addressUpdate[ 'shipping' ] = shipping
    }

    setOrder( addressUpdate )
    setConfirmCustomerInfo(true)
    setShowEdit(false)
  }

  useEffect(() => { 
    setInitialValues( { ...order[ addressType ], addressFormType: addressType } ) 
  }, [])
  
  const handleSwitchChange = () => {
    setShowAddressEditButton( !showAddressEditButton )
  }
  
  const handleSubmitClick = (values) => {
    setAddressFormValues(values)
    setConfirmCustomerInfo(true)
  }
  
  return (
      <>
          <Container maxWidth="md">
              { initialValues &&
                  <Formik 
                      initialValues={ initialValues } 
                      validationSchema={ AddressSchema } 
                      onSubmit={ (values) => { 
                        handleSubmitClick(values) 
                      } } 
                  >
                      {({ values, handleChange, handleBlur, errors, touched }) => {
                          return(
                              <Form autoComplete="on">
                                  <input name="addressType" type="hidden" value={ values.addressFormType } />
                                  <Grid container spacing={ 3 } sx={ { ...styles.grid } }>
                                      <Grid item xs={ 12 }>
                                          <Typography component="h3" sx={ { ...styles.title } } color="inherit" gutterBottom>
                                              { addressType }
                                          </Typography>
                                      </Grid>
                                      <Grid item sm={ 6 }>
                                          <TextField fullWidth={ true } error={ errors.firstName && touched.firstName } helperText={ (errors.firstName && touched.firstName) && errors.firstName } sx={ { ...styles.overrides } } id="firstName" variant="outlined" onChange={ handleChange } onBlur={ handleBlur } name="firstName" placeholder={ addressObj.firstName } value={ values.firstName } />
                                      </Grid>
                                      <Grid item sm={ 6 }>
                                          <TextField fullWidth={ true } error={ errors.lastName && touched.lastName } helperText={ (errors.lastName && touched.lastName) && errors.lastName } sx={ { ...styles.MuiInputBase } } id="lastName" variant="outlined" onChange={ handleChange } onBlur={ handleBlur } name="lastName" placeholder={ addressObj.lastName } value={ values.lastName }  />
                                      </Grid>
                                      { addressType == 'billing' &&
                                          <>
                                              <Grid item sm={ 6 }>
                                                  <InputMask mask="999-999-9999" onChange={ handleChange } onBlur={ handleBlur } value={ values.phone } error={ errors.phone && touched.phone } helperText={ (errors.phone && touched.phone) && errors.phone }>
                                                      {(inputProps) => <TextField fullWidth={ true } { ...inputProps } sx={ { ...styles.MuiInputBase } } id="phone" variant="outlined"  name="phone" placeholder={ addressObj.phone }  />}
                                                  </InputMask>
                                              </Grid>
                                              <Grid item sm={ 6 }>
                                                  <TextField fullWidth={ true } error={ errors.email && touched.email } helperText={ errors && errors.email } sx={ { ...styles.overrides } } id="email" type="email" variant="outlined" onChange={ handleChange } onBlur={ handleBlur } name="email" placeholder={ addressObj.email } value={ values.email }/>
                                              </Grid>
                                          </>
                                      }
                                      <Grid item sm={ 12 }>
                                          <Box sx={ { ...styles.addressBox } }>
                                              <GoogleAutocomplete addressFunc={ setAddressValues } />
                                              <AutoUpdateAddress addressType={ addressType } selectedAddress={ addressValues } />
                                          </Box>
                                      </Grid>
                                      <Grid item sm={ 6 }>
                                          <TextField fullWidth={ true } error={ errors.address1 && touched.address1 } helperText={ (errors.address1 && touched.address1) && errors.address1 } sx={ { ...styles.overrides } } id="address1" variant="outlined" autoComplete="street_address" onChange={ handleChange } onBlur={ handleBlur } name="address1" placeholder={ addressObj.address1 } value={ values.address1 } />
                                      </Grid>
                                      <Grid item sm={ 6 }>
                                          <TextField fullWidth={ true } sx={ { ...styles.MuiInputBase } } id="address2" variant="outlined" onChange={ handleChange } onBlur={ handleBlur } name="address2" placeholder={ addressObj.address2 } value={ values.address2 ?? '' } />
                                      </Grid>
                                      <Grid item sm={ 6 }>
                                          <TextField fullWidth={ true } error={ errors.city && touched.city } helperText={ (errors.city && touched.city) && errors.city } sx={ { ...styles.overrides } } id="city" variant="outlined" onChange={ handleChange } onBlur={ handleBlur } name="city" placeholder={ addressObj.city } value={ values.city } />
                                      </Grid>
                                      <Grid item sm={ 6 }>
                                          <StateSelect name="state" handleBlur={ handleBlur } handleChange={ handleChange } placeholder={ addressObj.state } value={ values.state } error={ errors.state } touched={ touched.state } label="state" />
                                      </Grid>
                                      <Grid item sm={ 6 }>
                                          <TextField fullWidth={ true } error={ errors.postcode && touched.postcode } helperText={ (errors.postcode && touched.postcode) && errors.postcode } sx={ { ...styles.overrides } } id="postcode" variant="outlined" onChange={ handleChange } onBlur={ handleBlur } name="postcode" placeholder={ addressObj.postcode } value={ values.postcode } />
                                      </Grid>
                                      <Grid item sm={ 6 } />
                                      { addressType == 'billing' &&
                                          <Grid item xs={ 12 }>
                                              <FormControlLabel
                                                control={ <Switch name="shippingChecked" color='secondary' checked={ showAddressEditButton } onChange={ handleSwitchChange } /> }
                                                label="Shipping Same As Billing"
                                              />
                                          </Grid>
                                      }
                                      <Grid item xs={ 12 }>
                                          <Button sx={ { ...styles.button } } variant="contained" type="submit">Continue</Button>
                                      </Grid>
                                  </Grid>
                              </Form>
                          )
                      }}
                  </Formik>
              }
          </Container>
          <Dialog
            open={ confirmCustomerInfo }
            onClose={ handleConfirmClose }
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
              <DialogTitle id="alert-dialog-title">Please Confirm All Customer Information</DialogTitle>
              <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                      Please verify all customer information is correct before proceeding with the Affirm Submission.
                  </DialogContentText>
              </DialogContent>
              <DialogActions>
                  <Button onClick={ handleConfirmClose } color="primary">Cancel</Button>
                  <Button onClick={ handleConfirmContinue } color="secondary" autoFocus>Continue</Button>
              </DialogActions>
          </Dialog>
      </>        
  )  
}

export default AddressFieldEdit;