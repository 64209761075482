import { gql } from '@apollo/client';

const REFRESH_USER = gql`
  mutation REFRESH_MUTATION($input: RefreshJwtAuthTokenInput!) {
    login( input: $input ) {
      authToken
    }
  }
`;

export default REFRESH_USER;