import { gql } from '@apollo/client'
import { ORDER_FIELDS } from '../fragments/order';

const UPDATE_ORDER = gql`
  ${ ORDER_FIELDS }
  mutation UPDATE_ORDER($input: UpdateOrderInput!) {
    updateOrder(input: $input) {
      order {
        ...OrderFields
      }
    }
  }
`;

export default UPDATE_ORDER