import React, { useContext } from 'react';

import { Paper } from '@mui/material';
import { Grid } from '@mui/material';

import { AppContext } from '../context/AppContext';

import Addresses from '../components/addresses';
import Products from '../components/products';

const styles = {
  root: {
    width: '100%',
  },
  notice: {
    backgroundColor: (theme) => theme.palette.background.paper,
    padding: (theme) => theme.spacing(2),
  },
  button: {
    marginTop: (theme) => theme.spacing(1),
    marginRight: (theme) => theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: (theme) => theme.spacing(2),
    marginTop: (theme) => theme.spacing(3),
  },
  resetContainer: {
    padding: (theme) => theme.spacing(3),
  },
  paper: {
    padding: (theme) => theme.spacing(6),
  },
  circularProgress: {
    verticalAlign: 'bottom',
    color: (theme) => theme.palette.secondary.main,
    width: '30px',
    height: '30px',
  },
}

const Telesales = () => {
  const { addresses } = useContext( AppContext );

  const addressObj = addresses && {
    shipping: {
      name: {
        full: (addresses.shipping_firstName && addresses.shipping_lastName) ? `${ addresses.shipping_firstName } ${ addresses.shipping_lastName }` : `${ addresses.billing_firstName } ${ addresses.billing_lastName }`,
        first: (addresses.shipping_firstName) ? addresses.shipping_firstName : addresses.billing_firstName,
        last: (addresses.shipping_lastName) ? addresses.shipping_lastName : addresses.billing_lastName,
      },
      address: {
        line1: (addresses.shipping_address1) ? addresses.shipping_address1 : addresses.billing_address1,
        line2: (addresses.shipping_address2) ? addresses.shipping_address2 : addresses.billing_address2,
        city: (addresses.shipping_city) ? addresses.shipping_city : addresses.billing_city,
        state: (addresses.shipping_state) ? addresses.shipping_state : addresses.billing_state,
        zipcode: (addresses.shipping_postcode) ? addresses.shipping_postcode : addresses.billing_postcode,
        country: 'US',
      }
    },
    billing: {
      name: {
        full: `${ addresses.billing_firstName } ${ addresses.billing_lastName }`,
        first: addresses.billing_firstName,
        last: addresses.billing_lastName,
      },
      address: {
        line1: addresses.billing_address1,
        line2: addresses.billing_address2,
        city: addresses.billing_city,
        state: addresses.billing_state,
        zipcode: addresses.billing_postcode,
        country: 'US',
      },
      phone_number: addresses.billing_phone,
      email: addresses.billing_email,
    },
  };
 
  return (
      <Paper sx={ { ...styles.paper } }>
          <div sx={ { ...styles.root } }>
              <Grid container spacing={ 4 }>
                  { (addressObj) ?
                      <Grid item xs={ 12 }>
                          <Addresses addresses={ addressObj } />
                      </Grid>
            : ''
          }
                  <Grid item xs={ 12 }>
                      <Products addresses={ addressObj } />
                  </Grid>
              </Grid>
          </div>
      </Paper>
  )
}

export default Telesales;