import { gql } from '@apollo/client';
import { ORDER_FIELDS } from '../fragments/order';

const GET_ORDER = gql`
  ${ ORDER_FIELDS }
  query GetOrder ($ids: [Int]) {
    orders(where: {include: $ids}) {
      nodes {
        ...OrderFields
      }
    }
  }
`;

export default GET_ORDER;