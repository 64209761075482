import React from 'react';
import { Navigate, useLocation, Outlet } from 'react-router-dom';

import { useAuth } from '../hooks/use-auth';

const PrivateRoute = () => {
  const auth = useAuth();
  const location = useLocation();

  if (!auth.user) {
    return <Navigate to='/login' state={ { from: location } } />
  }

  return location.pathname === '/' ? <Navigate to='/home' /> : <Outlet />
}

export default PrivateRoute;