import React, { useContext } from 'react';
import { Box, Typography } from '@mui/material';
import { AppBar } from '@mui/material';
import { Toolbar } from '@mui/material';
import { Link } from '@mui/material';
import { Button } from '@mui/material';
import { NavLink } from 'react-router-dom';
import logo from '../assets/img/tonal-logo-202004.png';
import { useAuth } from '../hooks/use-auth';
import { AppContext } from '../context/AppContext';

const styles = {
  root: {
    flexGrow: 0,
  },
  menuButton: {
    marginRight: (theme) => theme.spacing(2),
  },
  title: {
    textTransform: 'uppercase',
    flexGrow: 1,
  },
  logo: {
    height: '30px',
    marginRight: '1rem'
  },
  link: {
    color: '#fff',
    padding: (theme) => theme.spacing(3),
    textTransform: 'uppercase',
    fontWeight: 500,
    textAlign: 'center',
  },
  active: {
    color: (theme) => theme.palette.secondary.main,
  },
};

const Navbar = () => {
  const auth = useAuth();
  const { setAddresses, setOrder, nullOrder } = useContext(AppContext)

  const logout = () => {
    auth.signout()
    .then(() => {
      setAddresses(null)
      setOrder(nullOrder)
      sessionStorage.removeItem('userObj');
      sessionStorage.removeItem( 'woo-bs-order' )
    });

  }

  return (
      <AppBar sx={ { ...styles.root } } position="static">
          <Toolbar>
              <Box component="img"  sx={ { ...styles.logo } } src={ logo } title="tonal" alt="Tonal logo"/>
              <Typography variant="h6" sx={ { ...styles.title } }>
                  Telesales
              </Typography>
              {
                auth.user?
                    <>
                        <Link component={ NavLink } sx={ { ...styles.link } } to="/home">Home</Link>
                        <Link component={ NavLink } sx={ { ...styles.link } } to="/orders">Orders</Link>
                        <Link component={ NavLink } sx={ { ...styles.link } } to="/order/0">New Order</Link>
                        <Button sx={ { ...styles.link } } variant="text" onClick={ logout }>Logout</Button>
                    </>
          : ''
              }
          </Toolbar>
      </AppBar>
  )
}

export default Navbar;