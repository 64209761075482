import React from 'react';
import { AppProvider } from '../context/AppContext';
import Apollo from './apollo';
import { ProvideAuth } from '../hooks/use-auth';
import Theme from './theme'

const Provider = ( { children } ) => {
  return (
      <Apollo>
          <Theme>
              <AppProvider>
                  <ProvideAuth>
                      { children }
                  </ProvideAuth>
              </AppProvider>
          </Theme>
      </Apollo>
  )
}

export default Provider