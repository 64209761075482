import React, { useState, useContext } from 'react';
import { Button } from '@mui/material';
import { AppContext } from '../context/AppContext';
import AddressFieldEdit from './address-field-edit';
import AddressFieldView from './address-field-view';

const AddressField = ( { addressType, setShowAddressEditButton, showAddressEditButton } ) => {
  const [ showEdit, setShowEdit ] = useState(false)
  const { order } = useContext(AppContext);

  const addressObj = order[ addressType ]
  const cleanValues = Object.values(addressObj)
  cleanValues.pop()
  const addressIsEmpty = cleanValues.filter(el => el).length === 0 && addressObj.constructor === Object
  
  const handleClick = (event) => {
    event.preventDefault()
    setShowEdit( !showEdit )
  }

  return (
      <>
          {
            showEdit ?
                <AddressFieldEdit addressType={ addressType } setShowEdit={ setShowEdit } setShowAddressEditButton={ setShowAddressEditButton } showAddressEditButton={ showAddressEditButton } />
            :
                <AddressFieldView addressType={ addressType } />
          }
          {
            ( ( 'billing' === addressType && !showEdit ) || ( 'shipping' === addressType && ( !showAddressEditButton || !addressIsEmpty )  &&  !showEdit )  ) ?
                <Button variant="outlined" color="secondary" onClick={ handleClick }>{ addressIsEmpty ? 'Add' : 'Edit' } Address</Button>
            : ''
          }
      </>
  )  
}

export default AddressField;