import React, { useContext, useState } from 'react';
import { Card } from '@mui/material';
import { CardContent } from '@mui/material';
import { FormControl } from '@mui/material';
import { InputLabel } from '@mui/material';
import { MenuItem } from '@mui/material';
import { Select } from '@mui/material';
import { Typography } from '@mui/material';

import { AppContext } from '../context/AppContext';

const styles = {
  formControl: {
    margin: (theme) => theme.spacing(1),
    minWidth: '35%',
  },
  selectEmpty: {
    color: (theme) => theme.palette.secondary.main,
    marginTop: (theme) => theme.spacing(2),
  },
  select: {
    paddingTop: (theme) => theme.spacing(2),
  },
  card: {
    padding: (theme) => theme.spacing(2),
    height: 'auto%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: (theme) => theme.palette.background.paper,
    marginLeft: 'auto',
    marginRight: 'auto',
    borderRadius: '4px',
    marginTop: (theme) => theme.spacing(2),
    marginBottom: (theme) => theme.spacing(2),
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'center',
    flexGrow: 1,
  },
  title: {
    textAlign: 'center',
    fontWeight: 700,
    color: '#fff',
    paddingBottom: (theme) => theme.spacing(2),
  },
};

const SalesLocationsSelect = () => {
  const { salesLocations, setSalesLocation, salesLocation } = useContext(AppContext)

  const handleSelect = (event) => {
    setSalesLocation(event.target.value)
  }

  return (
      <Card sx={ { ...styles.card } }>
          <CardContent sx={ { ...styles.cardContent } }>
              <FormControl sx={ { ...styles.formControl } }>
                  <InputLabel id='retail-sales-location-label'>
                      <Typography gutterBottom sx={ { ...styles.title } } variant="h6" component="h1">
                          Retail Sales Location
                      </Typography>
                  </InputLabel>
                  <Select
                    labelId='retail-sales-location-label'
                    id="retail-sales-location"
                    value={ salesLocation }
                    displayEmpty
                    sx={ { ...styles.select, ...styles.selectEmpty } }
                    onChange={ handleSelect }
                  >
                      <MenuItem value=""><em>None</em></MenuItem>
                      {
                        salesLocations.tonalSalesLocations.map( tonalSalesLocation => {
                          return <MenuItem key={ tonalSalesLocation.key } value={ tonalSalesLocation.key }>{tonalSalesLocation.label}</MenuItem>
                        })
                      }
                  </Select>
              </FormControl>
          </CardContent>
      </Card>
  )
}

export default SalesLocationsSelect