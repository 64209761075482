import React from 'react';
import { ThemeProvider as MuiThemeProvider, createTheme, StyledEngineProvider } from '@mui/material/styles';
import { ThemeProvider } from '@emotion/react';

const Theme = ({ children }) => {
  const theme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#000',
        contrastText: '#fff',
      },
      secondary: {
        main: '#11DDC4',
        dark: '#11DDC4',
        contrastText: '#000',
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          contained: {       
            backgroundColor: 'rgb(17, 221, 196)',
            '&:hover': {
              backgroundColor: 'rgb(17, 221, 196)',
              filter: 'brightness(75%)'
            },
          },
          text: {
            color: '#11DDC4',
            '&:hover': {
              color: '#11DDC4',
              filter: 'brightness(50%)'
            }
          }
        }
      },
      MuiAvatar: {
        styleOverrides: {
          root: {
            backgroundColor: 'rgb(17, 221, 196)',
          }
        }
      },
      MuiLink: {
        styleOverrides: {
          root: {
            color: '#11DDC4',
            '&:hover': {
              color: '#11DDC4',
              filter: 'brightness(50%)'
            }
          }
        }
      },
      MuiCircularProgress: {
        styleOverrides: {
          root: {
            color: 'rgb(17, 221, 196)'
          }
        }
      },
      MuiTablePagination: {
        styleOverrides: {
          displayedRows: {
            display: 'none',
          }
        }
      }
    }
  });
  
  return(
      <StyledEngineProvider injectFirst>
          <MuiThemeProvider theme={ theme }>
              <ThemeProvider theme={ theme }>
                  { children }
              </ThemeProvider>
          </MuiThemeProvider>
      </StyledEngineProvider>
  )
}

export default Theme