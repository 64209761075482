import React, { useState } from 'react';
import { Card } from '@mui/material';
import { CardContent } from '@mui/material';
import { Grid } from '@mui/material';
import { Box } from '@mui/material';

import AddressField from './address-field';
import PriceTable from './price-table';
import StartCheckout from './start-checkout';
import ProductSelect from './product-select';
import SalesLocationsSelect from './sales-locations-select';

const styles = {
  root: {
    flexGrow: 1,
  },
  card: {
    padding: (theme) => theme.spacing(2),
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: (theme) => theme.palette.text.secondary,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  cardContent: {
    flexGrow: 1,
  },
  title: {
    color: '#000',
    textAlign: 'center',
  },
  addresses: {
    backgroundColor: (theme) => theme.palette.background.paper,
    padding: (theme) => theme.spacing(2),
    borderRadius: '4px',
    marginTop: (theme) => theme.spacing(2),
    marginBottom: (theme) => theme.spacing(2),
  }
}

const Order = ( props ) => {
  const { order, addresses, resetFunc, discountFunc, dialogFunc, closeDialog, loadingCoupon, dialogOpen, disableTelesales, setDisableTelesales } = props;

  const [ showAddressEditButton, setShowAddressEditButton ] = useState(true)

  return (
      <Grid container spacing={ 4 }>
          <Grid item xs={ 12 }>
              <Card sx={ { ...styles.card } }>
                  <CardContent>
                      <ProductSelect/>
                      <SalesLocationsSelect/>
                      <Box sx={ { ...styles.addresses } }>
                          <h3>Addresses</h3>
                          <hr/>
                          <Grid container>
                              <Grid item xs={ 12 }>                                
                                  <h4>Billing</h4>
                                  <AddressField showAddressEditButton={ showAddressEditButton } setShowAddressEditButton={ setShowAddressEditButton } addressType={ 'billing' } />
                              </Grid>
                              <Grid item xs={ 12 }>                                
                                  <h4>Shipping</h4>
                                  <AddressField showAddressEditButton={ showAddressEditButton } setShowAddressEditButton={ setShowAddressEditButton } addressType={ 'shipping' } />
                              </Grid>
                          </Grid>
                      </Box>
                      <PriceTable
                        order={ order }
                        handleDiscount={ discountFunc }
                        handleDialog={ dialogFunc }
                        handleCloseDialog={ closeDialog }
                        loadingCoupon={ loadingCoupon }
                        dialogOpen={ dialogOpen }
                        />
                      <StartCheckout
                        order={ order }
                        addresses={ addresses }
                        resetFunc={ resetFunc }
                        disableTelesales={ disableTelesales }
                        setDisableTelesales={ setDisableTelesales }
                      />
                  </CardContent>
              </Card>
          </Grid>
      </Grid>
  );
}

export default Order;