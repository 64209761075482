import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Billing from './pages/billing';
import CacheBuster from './cache-buster';
import Home from './components/home';
import Layout from './components/layout';
import LoginForm from './pages/login-form';
import OrderPage from './pages/order-page';
import Orders from './pages/orders';
import PrivateRoute from './components/private-route';
import Provider from './providers/provider';
import Telesales from './pages/telesales';

import './index.css'

const App = () => {

  return (
      <CacheBuster>
          <Provider>
              <Router>
                  <Routes>
                      <Route element={ <Layout /> }>
                          <Route path="/login" element={ <LoginForm/> } />
                          <Route path="/" element={ <PrivateRoute /> }>
                              <Route path="/billing" element={ <Billing/> } />
                              <Route path="/telesales" element={ <Telesales/> } />
                              <Route path="/order/:orderId" element={ <OrderPage/> } />
                              <Route path="/orders" element={ <Orders/> } />
                              <Route path="/home" element={ <Home /> } />
                          </Route>
                      </Route>
                  </Routes>
              </Router>
          </Provider>
      </CacheBuster>
);
}

export default App;
