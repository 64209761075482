import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { Button } from '@mui/material';
import { Alert } from '@mui/material';
import { Slide } from '@mui/material';
import { CircularProgress } from '@mui/material';
import { fetch } from 'cross-fetch';
import { useMutation } from '@apollo/client';
import { v4 } from 'uuid';

import currency from 'currency.js';
import { subscribeToAffirm } from './api/api';
import AFFIRM_UPDATE_ORDER from '../mutations/update-affirm-order';

const styles = {
  start: {
    margin: (theme) => theme.spacing(3, 0, 2),
    textTransform: 'uppercase',
    backgroundColor: (theme) => theme.palette.secondary.main,
    color: (theme) => theme.palette.primary.main,
    width: '100%',
    fontSize: '1rem',
    padding: (theme) => theme.spacing(2),
  },
  buttonProgress: {
    color: (theme) => theme.palette.primary.main,
    opacity: 0.7,
    position: 'absolute',
    top: '35%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  startCheckout: {
    position: 'relative',
  },
  alertBox: {
    height: '50px',
  },
}

const StartCheckout = ( props ) => {
  const { order, disableTelesales, setDisableTelesales } = props;
  const [ telesales, setTelesales ] = useState({});
  const [ telesalesLoading, setTelesalesLoading ] = useState(false);
  const [ telesalesSuccess, setTelesalesSuccess ] = useState(false);
  const [ open, setOpen ] = useState(false);
  const [ snackPack, setSnackPack ] = useState([]);
  const [ updateAffirmOrder ] = useMutation(AFFIRM_UPDATE_ORDER);

  const handleCloseClick = () => {
    setOpen(false);
  }

  const handleMessage = (message) => {
    setSnackPack((prev) => [ ...prev, { ...message, key: new Date().getTime() } ])
  }

  const [ AffirmMessage, setAffirmMessage ] = useState(undefined);
  useEffect(() => {
    subscribeToAffirm((err, message) => {
      const confirmed = 'confirmed';
      const cancelled = 'cancelled';
      if(telesales.checkout_id === message.checkout_token && confirmed === message.event) {
        handleMessage({ ...message, severity: 'success', event: 'Current Customer completed Tonal order.' });
        setTelesalesSuccess(true);
      } else if(telesales.checkout_id === message.checkout_token && cancelled === message.event ) {
        handleMessage({ ...message, severity: 'info', event: 'Current Customer cancelled Tonal order.' });
        setDisableTelesales(true);
      }

      if(err) {
        console.error(err)
        handleMessage({ ...err, severity: 'error' });
      }
      setTelesalesLoading(![ confirmed, cancelled ].includes(message.event) )
    });

    if(snackPack.length && !AffirmMessage) {
      setAffirmMessage({ ...snackPack[ 0 ] })
      setSnackPack((prev) => prev.slice(1))
      setOpen(true)
    } else if (snackPack.length && AffirmMessage && open) {
      setTimeout(() => {
        setOpen(false)
        setAffirmMessage(undefined)
      }
      , 12000);
    }
  },[ snackPack, AffirmMessage, open, telesales ])
  
  const discountMapToObject = ( discountLines ) => {
    const discounts = new Map();
    discountLines.forEach(discountLine => {
      discounts.set( discountLine.code, { discount_amount: currency(discountLine.coupon.amount).intValue } )
    });

    return Object.fromEntries(discounts);
  }

  const sendTelesales = () => {
    setTelesalesLoading(true);
    setDisableTelesales(false);
    handleMessage({ event: 'Building Telesales order.' })

    const lineItems = order.lineItems.nodes.map(lineItem => {
      return {
        display_name: lineItem.product.node.name,
        sku: lineItem.product.node.sku,
        unit_price: currency(lineItem.product.node.price).intValue,
        qty: lineItem.quantity,
      }
    })

    const data = {
      shipping: {
        name: {
          full: `${ order.shipping.firstName } ${ order.shipping.lastName }`,
          first: order.shipping.firstName,
          last: order.shipping.lastName,
        },
        address: {
          line1: order.shipping.address1,
          city: order.shipping.city,
          state: order.shipping.state,
          zipcode: order.shipping.postcode,
          country: order.shipping.country,
        },
      },
      billing: {
        name: {
          full: `${ order.billing.firstName } ${ order.billing.lastName }`,
          first: order.billing.firstName,
          last: order.billing.lastName,
        },
        address: {
          line1: order.billing.address1,
          city: order.billing.city,
          state: order.billing.state,
          zipcode: order.billing.postcode,
          country: order.billing.country,
        },
        phone_number: order.billing.phone,
        email: order.billing.email,
      },
      order_id: order.databaseId.toString(),
      items: lineItems,
      discounts: discountMapToObject(order.couponLines.nodes),
      total: currency(order.total).intValue,
      shipping_amount: currency( order.shippingTotal ).intValue,
      tax_amount: currency( order.totalTax ).intValue,
      merchant: {
        public_api_key: process.env.REACT_APP_AFFIRM_PUB_KEY,
        user_cancel_url: `${ process.env.REACT_APP_TELESALES_API_URL + process.env.REACT_APP_AFFIRM_CANCEL_URL }/${ order.databaseId }`,
        user_confirmation_url: process.env.REACT_APP_TELESALES_API_URL + process.env.REACT_APP_AFFIRM_CONFIRM_URL,
      },
      paymentMethod: 'affirm'
    };

    fetch(
      process.env.REACT_APP_AFFIRM_API_URL + process.env.REACT_APP_AFFIRM_CHECKOUT_ENDPOINT,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      }
    )
    .then(response => {
      if(400 <= response.status) {
        console.error(response.status)
        handleMessage({ message: response.message, severity: 'error' });
        setTelesalesLoading(false);
        setDisableTelesales(false)
        return false
      }

      return response.json()
    })
    .then(result => {
      setTelesales(result);
      handleMessage({ event: 'Telesales Order Delivered Successfully.' })
      return result
    })
    .then(affirm => {
      if(affirm.checkout_id) {
        handleMessage({ event: `Registering Telesales Order Transaction: ${ affirm.checkout_id }. `, severity: 'success' })
        
        updateAffirmOrder({
          variables: {
            input: {
              clientMutationId: v4(),
              orderId: order.databaseId,
              checkoutId: affirm.checkout_id,
            }
          }
        })
      }
    })
    .catch(error => {
      console.error(error)
      handleMessage({ event: error.message, severity: 'error' })
      setTelesalesLoading(false)
    })
  }

  return (
      <div sx={ { ...styles.startCheckout } }>
          {
              order.databaseId ?
                  <Button sx={ { ...styles.start } } variant="contained" type="submit" onClick={ sendTelesales } disabled={ telesalesSuccess } >{ telesalesSuccess ? 'Affirm Complete' : 'Start Affirm'}</Button>
              : ''
          }
          { telesalesLoading && <CircularProgress size={ 24 } sx={ { ...styles.buttonProgress } } /> }
          <Box sx={ { ...styles.alertBox } }>
              <Slide direction="right" in={ open } mountOnEnter unmountOnExit>
                  <Alert onClose={ handleCloseClick } severity={ AffirmMessage && AffirmMessage.severity ? AffirmMessage.severity : 'info' }>
                      {AffirmMessage ? AffirmMessage.event : undefined}
                  </Alert>
              </Slide>
          </Box>
      </div>
  );
}

export default StartCheckout;