import { gql } from '@apollo/client';

const AFFIRM_UPDATE_ORDER = gql`
  mutation ($input: AffirmOrderUpdateInput!) {
    affirmOrderUpdate(input: $input) {
      orderId
      checkoutAdded
    }
  }
`;

export default AFFIRM_UPDATE_ORDER;