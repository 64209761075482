import React, { useState, useEffect, useContext, createContext } from 'react';
import { useMutation } from '@apollo/client';
import LOGIN_USER from '../mutations/login';
import LOGOUT_USER from '../mutations/logout';
import REFRESH_USER from '../mutations/refresh';

import { v4 } from 'uuid';
import _ from 'lodash';

const authContext = createContext();

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={ auth }>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
}

function useProvideAuth() {
  const [ user, setUser ] = useState(() => {
    const localUser = (sessionStorage.getItem('userObj')) ? JSON.parse(sessionStorage.getItem('userObj')) : null;
    return localUser;
  });
  const [ counter, setCounter ] = useState(0);

  useEffect(() => {
    if(user) {
      sessionStorage.setItem('userObj', JSON.stringify(user));

    } else {
      sessionStorage.removeItem('userObj');
    }
  });

  const [ login, { loading: loginLoading, error: loginError } ] = useMutation(LOGIN_USER, {
    onCompleted: (data) => {
      
      setUser(data);
      
      return user;
    },
    onError: ({ graphQLErrors, networkError }) => {
      if ( graphQLErrors ) {
        graphQLErrors.forEach(({ message, locations, path }) =>
          console.error(`[GraphQL error]: Message: ${ message }, Location: ${ locations }, Path: ${ path }`)
        )
      }

      if (networkError) console.error(`[Network error]: ${ networkError }`);
    }
  },{ errorPolicy: 'all' })

  const [ logout ] = useMutation(LOGOUT_USER, {
    onCompleted: () => {
      setUser(null);
      return user;
    },
    onError: (error) => {
      setUser(null)
    }
  },{ errorPolicy: 'all' })

  const signin = (inUsername, inPassword) => {
    return login({ variables:{ input: { clientMutationId: v4(), password: inPassword, username: inUsername } } });
  }

  const signout = () => {
    return logout({ variables: { input: { clientMutationId: v4() } } })
  }

  return { user, signin, loginLoading, loginError, signout };
}