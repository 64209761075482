import React, { useState, useRef, useMemo, useEffect } from 'react';
import { Grid } from '@mui/material';
import { TextField } from '@mui/material';
import { Typography } from '@mui/material';
import { Autocomplete } from '@mui/material';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';

function loadScript(src, position, id) {
  if(!position) {
    return
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src
  position.appendChild(script)
}

const autocompleteService = { current: null }
const geocoderService     = { current: null }

const GoogleAutocomplete = ( { addressFunc }  ) => {
  const [ searchValue, setSearchValue ] = useState('');
  const [ value, setValue ] = useState('');
  const [ options, setOptions ] = useState([]);
  const loaded = useRef(false);
  
  if( typeof window !== 'undefined' && !loaded.current ) {
    if(!document.querySelector('#google-maps')) {
      loadScript(
        `//maps.googleapis.com/maps/api/js?key=${ process.env.REACT_APP_GOOGLE_API_KEY }&language=en&libraries=places,geocoding`,
        document.querySelector('head'),
        'google-maps'
      )
    }
  
    loaded.current = true;
  }
  
  const fetch = useMemo(
    () => 
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback)
      }, 200),
    [],
  )

  useEffect(() => {
    let active = true;
  
    if(!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
  
    if(!autocompleteService.current) {
      return undefined;
    }
  
    if(!geocoderService.current && window.google) {
      geocoderService.current = new window.google.maps.Geocoder();
    }
  
    if(!geocoderService.current) {
      return undefined;
    }
  
    if(searchValue === '') {
      setOptions(value ? [ value ] : []);
      return undefined;
    }
  
    fetch({ input: searchValue }, (results) => {
      if(active) {
        let newOptions = [];
  
        if(value) {
          newOptions = [ value ]
          geocoderService.current.geocode( {
            placeId: value.place_id
          }, (geocoderesults, status) => {
            if(status === 'OK' && geocoderesults[ 0 ]) {
              addressFunc(geocoderesults[ 0 ])
            }
          } )
        }
  
        if(results) {
          newOptions = [ ...newOptions, ...results ];
        }
  
        setOptions(newOptions);
      }
    })
  
    return () => {
      active = false;
    }
  }, [ value, searchValue, fetch, addressFunc ])

  return (
      <Autocomplete
      id="google-map-search"
      getOptionLabel={ (option) => (typeof option === 'string') ? option : option.description }
      filterOptions={ (x) => x }
      options={ options }
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={ value }
      onChange={ (event, newValue) => {
        setOptions(newValue ? [ newValue, ...options ] : options)
        setValue(newValue)
      } }
      onInputChange={ (event, newInputValue) => {
        setSearchValue(newInputValue)
      } }
      renderInput={ (params) => (
          <TextField { ...params } label="Start here to find an address" variant="outlined" fullWidth={ true } />
      ) }
      renderOption={ (props, option) => {
        const matches = option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [ match.offset, match.offset + match.length ])
        );

        return (
            <li { ...props }>
                <Grid container alignItems="center">
                    <Grid item>
                        {parts.map((part, index) => (
                          // eslint-disable-next-line react/no-array-index-key
                            <span key={ index } style={ { fontWeight: part.highlight ? 700 : 400 } }>
                                {part.text}
                            </span>
                  ))}
                        <Typography variant="body2" color="textSecondary">
                            {option.structured_formatting.secondary_text}
                        </Typography>
                    </Grid>
                </Grid>
            </li>
        )
      } }
    />
  )
}

export default GoogleAutocomplete;