import React, { useState, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import { Backdrop } from '@mui/material';
import { Grid } from '@mui/material';
import { CircularProgress } from '@mui/material';
import { Snackbar } from '@mui/material';
import { Alert } from '@mui/material';
import { Box } from '@mui/material';
import { useQuery, useMutation } from '@apollo/client';
import { v4 } from 'uuid';
import { findWhere, isMatch } from 'underscore';

import Order from './order';
import NewOrderButton from './new-order-button';

import PRODUCTS_QUERY from '../queries/products';
import CREATE_ORDER from '../mutations/create-order';
import UPDATE_ORDER from '../mutations/update-order';

const styles = { 
  circularProgress: {
    color: (theme) => theme.palette.secondary.main,
    width: '30px',
    height: '30px',
  },
  holdingBox: {
    width: '100%',
    height: '900px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  close: {
    padding: (theme) => theme.spacing(0.5),
  },
}

const updateOrders = ( newOrder, orders ) => {
  const replaceOrderIndex = orders.findIndex(order => isMatch(order, { databaseId: newOrder.databaseId }));
  orders.splice(replaceOrderIndex, 1, newOrder);
  return orders;
}

const handleDialog = ( orderToFlip, orders) => {
  const flippedOrder = { ...orderToFlip, dialogOpen: !orderToFlip.dialogOpen }
  return updateOrders( flippedOrder, orders );
}

const setDialogClose = ( orderToFlip, orders ) => {
  const flippedOrder = { ...orderToFlip, dialogOpen: false }
  return updateOrders( flippedOrder, orders );
}

const cancelDialog = ( orders ) => {
  console.log( orders );
  const closedOrders = orders.map( (orderToClose ) => { return { ...orderToClose, dialogOpen: false } } )
  return closedOrders
}

const orderReducer = ( state, action ) => {
  switch( action.type ) {
    case 'insert': {
      const newOrder = { ...action.payload, dialogOpen: false }
      return { orders: [ ...state.orders, newOrder ] }
    }
    case 'update':
      return { orders: updateOrders(action.payload, state.orders) }
    case 'flipDialog':
      return { orders: handleDialog(action.payload, state.orders) }
    case 'closeDialog':
      return { orders: setDialogClose(action.payload, state.orders) }
    case 'cancelDialog':
      return { orders: cancelDialog(state.orders) }
    case 'reset':
      return []
    default:
      throw new Error();
  }
}

const orders = { orders: [] };

const Products = ( props ) => {
  const [ open, setOpen ] = useState(false);
  const [ requestError, setRequestError ] = useState(null);
  const [ products, setProducts ] = useState([]);
  const [ state, dispatch ] = useReducer( orderReducer, orders )
  const [ createOrder ] = useMutation(CREATE_ORDER);
  const [ applyCoupon, { loading: loadingCoupon } ] = useMutation(UPDATE_ORDER);

  const { addresses } = props;
  const navigate = useNavigate();

  const resetSales = () => {
    // dispatch({type: 'reset'});
    //TODO: clean up unused orders
    navigate('/home', { replace: true });
  }

  const handleDiscount = (event) => {
    event.preventDefault()

    const orderId = event.currentTarget.dataset.orderId
    const discountCodesElement = document.querySelector(`#discount-${ orderId }`)
    const appliedCodes = discountCodesElement.value.trim().split(',').filter( String );
    const order = findWhere(state.orders, { databaseId: Number.parseInt(orderId) })
    const orderCodes = order?.couponLines?.nodes.map(couponLine => couponLine.coupon.code);
    const unfilteredCodes = appliedCodes.concat(orderCodes);
    const codes = unfilteredCodes.filter(code => undefined !== code );
    
    applyCoupon({
      variables: {
        input:{
          clientMutationId: v4(),
          orderId: Number.parseInt(orderId),
          isPaid: false,
          coupons: codes,
        },
      },
    })
    .then((result) => {dispatch({ type: 'closeDialog', payload: result.data.updateOrder.order })})
    .catch((err) => {
      setOpen(true)
      setRequestError(err.message)
    })
  }

  const handleDiscountDialog = (event) => {
    event.preventDefault();
    const orderId = event.currentTarget.dataset.orderId
    const order = findWhere(state.orders, { databaseId: Number.parseInt(orderId) })
    dispatch({ type: 'flipDialog', payload: order })
  }

  const handleClickClose = () => {
    setOpen(false);
  }

  const handleCloseDialog = () => { 
    const dialog = document.querySelector('.dialog');
    const orderId = dialog.dataset.orderId;
    const order = findWhere(state.orders, { databaseId: Number.parseInt(orderId) })
    dispatch({ type: 'flipDialog', payload: order }) 
  }

  const { data, loading, error } = useQuery( PRODUCTS_QUERY, {
    onError: ( queryError ) => {
      setOpen( true );
      setRequestError( Object.entries(queryError) );
    },
    onCompleted: ( response ) => {     
      
      response.tonal.nodes.forEach((tonal) => {
        setProducts([ ...products, tonal ]);
        const orderVariables = {
          input: {
            clientMutationId: v4(),
            isPaid: false,
            shippingLines: [
              {
                methodId: 'flat_rate',
                methodTitle: 'Delivery & Installation',
                total: (addresses.shipping.address.state === 'AK' || addresses.shipping.address.state === 'HI') ? '550' : '250',
              },
            ],
            lineItems: [
              {
                id: tonal.id,
                productId: tonal.databaseId,
                name: tonal.name,
                quantity: 1,
                total: tonal.price
              },
            ],
            billing: {
              address1: addresses.billing.address.line1,
              address2: addresses.billing.address.line2,
              city: addresses.billing.address.city,
              firstName: addresses.billing.name.first,
              lastName: addresses.billing.name.last,
              postcode: addresses.billing.address.zipcode,
              state: addresses.billing.address.state,
              phone: addresses.billing.phone_number,
              email: addresses.billing.email,
              country: 'US',
            },
            shipping: {          
              address1: addresses.shipping.address.line1,
              address2: addresses.shipping.address.line2,
              city: addresses.shipping.address.city,
              firstName: addresses.shipping.name.first,
              lastName: addresses.shipping.name.last,
              postcode: addresses.shipping.address.zipcode,
              state: addresses.shipping.address.state,
              country: 'US',
            }
          }
        }

        createOrder({
          variables: orderVariables,
          onError: (err) => {
            setOpen(true)
            console.error(err)
            setRequestError( err.message )
          },
        })
        .then( ( result ) => { dispatch( { type: 'insert', payload: result.data.createOrder.order } ) } )
        .catch((err) => {
          setOpen(true)
          setRequestError( err.message )
        })
      })
    },
  } );

  if( loading ) return ( <Backdrop open={ loading }><CircularProgress sx={ { ...styles.circularProgress } } /></Backdrop> )
  if( error ) {
    setOpen(true)
    setRequestError( error.message )
  }
  if( !data ) {
    setOpen( true )
    setRequestError( 'Nothing Found' )
  }

  return (
      <Grid container direction="row" justifyContent="center" alignItems="stretch" spacing={ 4 }>
          { 
        state.orders.length > 0 ?
        ( state.orders.map( (order) => {
          const prodId = order.lineItems.nodes[ 0 ].product.databaseId
          const product = products.find(prod => prod.databaseId == prodId)
          return (
              <Grid key={ order.databaseId } item xs={ 12 } sm={ 6 }>
                  <Order
                    order={ order }
                    product={ product }
                    addresses={ addresses }
                    resetFunc={ resetSales }
                    discountFunc={ handleDiscount }
                    dialogFunc={ handleDiscountDialog }
                    closeDialog={ handleCloseDialog }
                    loadingCoupon={ loadingCoupon }
                  />
              </Grid>
          )
        } ) )
        : <Box sx={ { ...styles.holdingBox } }><CircularProgress sx={ { ...styles.circularProgress } } /></Box>
      }
          <Grid item xs={ 12 }>
              <NewOrderButton handleNewOrder={ resetSales } />
          </Grid>
          <Grid item xs={ 12 }>
              <Snackbar
                open={ open }
                onClose={ handleClickClose }
                anchorOrigin={ { vertical: 'top', horizontal: 'left' } }
              >
                  <Alert severity="error" onClose={ handleClickClose }>{requestError}</Alert>
              </Snackbar>
          </Grid>
      </Grid>
  )
}
export default Products;