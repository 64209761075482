import { v4 } from 'uuid';
import RURAL_ZIP_CODES from '../data/shipping-zones';

export const createCheckoutData = ( order ) => {
	const checkoutData = {
		clientMutationId: v4(),

		billing: {
			firstName: order.firstName,
			lastName: order.lastName,
			address1: order.address1,
			address2: order.address2,
			city: order.city,
			country: order.country,
			state: order.state,
			postcode: order.postcode,
			email: order.email,
			phone: order.phone,
			company: order.company,
		},
		shipping: {
			firstName: order.firstName,
			lastName: order.lastName,
			address1: order.address1,
			address2: order.address2,
			city: order.city,
			country: order.country,
			state: order.state,
			postcode: order.postcode,
			email: order.email,
			phone: order.phone,
			company: order.company,
		},
		shipToDifferentAddress: false,
		paymentMethod: order.paymentMethod,
		isPaid: false,
		transactionId: 'hjkhjkhsdsdiui'
	};

	return checkoutData;
};

export const calculateShippingTotal = ( shippingPostcode, shippingState ) => {
	let shippingTotal = '295';  // Default
    if (shippingState === 'AK' || shippingState === 'HI') {
      shippingTotal = '550';  // Alaska/Hawaii pricing
    } else {
      // Loop through rural zip codes and check if the shipping postcode starts with one.
      // This is to account for ZIP+4 extensions.
      for (const ruralZipCode of RURAL_ZIP_CODES) {
        if (shippingPostcode.startsWith(ruralZipCode)) {
          shippingTotal = '495';  // Rural pricing
          break;
        }
      }
    }
	return shippingTotal
}

export const updateQuery = ( previousResult, { fetchMoreResult } ) => {
	return fetchMoreResult.orders.edges.length ? fetchMoreResult : previousResult
}