import * as Yup from 'yup';

const zipRegEx = new RegExp('[\\d]{5}(-[\\d]{4})?');
const phoneRegEx = new RegExp('^\\d{3}[\\s-]?\\d{3}[\\s-]?\\d{4}$');

Yup.addMethod(Yup.string, 'optional', function(isOptional = true, defaultValue = '') {
  return this.transform(function(value) {
    if(!isOptional) return value;

    if(value && !(value === null || value === undefined || value === '')) { return value; }

    return Yup.mixed().notRequired();
  });
});

const AddressSchema = Yup.object().shape({
  firstName: Yup.string().min(2, 'Too Short').max(50, 'Too Long').required('Required'),
  lastName: Yup.string().min(2, 'Too Short').max(50, 'Too Long').required('Required'),
  address1: Yup.string().min(5, 'Too Short').max(150, 'Too Long').required('Required'),
  city: Yup.string().min(2, 'Too Short').max(50, 'Too Long').required('Required'),
  state: Yup.string().min(2, 'Too Short').max(50, 'Too Long').required('Required'),
  postcode: Yup.string().matches(zipRegEx, 'Zip Code should look like `12345`, zip+4 optional').required('Required'),
  addressFormType: Yup.string().required(),
  phone: Yup.string().matches(phoneRegEx, 'Phone should look something like 555-555-5555')
    .when('addressFormType', {
      is: 'billing',
      then: Yup.string().required('Required'),
      otherwise: Yup.string().notRequired(),
    }
  ),
  email: Yup.string().email('Must Be A Valid Email Address')
    .when('addressFormType', {
      is: 'billing',
      then: Yup.string().required('Required'),
      otherwise: Yup.string().notRequired(),
    }
  ),
});

export default AddressSchema