import React, { useContext, useState, useEffect, Fragment } from 'react';
import { Box } from '@mui/material';
import { Paper } from '@mui/material';
import { Container } from '@mui/material';
import { Grid } from '@mui/material';
import { FormControlLabel } from '@mui/material';
import { Switch } from '@mui/material';
import { TextField } from '@mui/material';
import { Button } from '@mui/material';
import { Typography } from '@mui/material';
import { Dialog } from '@mui/material';
import { DialogActions } from '@mui/material';
import { DialogContent } from '@mui/material';
import { DialogContentText } from '@mui/material';
import { DialogTitle } from '@mui/material';
import { Navigate } from 'react-router-dom';
import { Formik, Form, useFormikContext } from 'formik';
import * as Yup from 'yup';
import InputMask from 'react-input-mask';

import StateSelect from '../components/state-select';
import GoogleAutocomplete from '../components/google-autocomplete'

import { AppContext, addressObj } from '../context/AppContext';
import { placeToAddress } from '../functions/geo';

const initialValues = {
  billing_firstName: '',
  billing_lastName: '',
  billing_address1: '',
  billing_address2: '',
  billing_city: '',
  billing_state: '',
  billing_postcode: '',
  billing_phone: '',
  billing_email: '',
  shipping_firstName: '',
  shipping_lastName: '',
  shipping_address1: '',
  shipping_address2: '',
  shipping_city: '',
  shipping_state: '',
  shipping_postcode: '',
  shippingChecked: true,
};

const styles = {
  grid: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  button: {
    margin: (theme) => theme.spacing(3, 0, 2),
    textTransform: 'uppercase',
    backgroundColor: (theme) => theme.palette.secondary.main,
    color: (theme) => theme.palette.primary.main,
  },
  addressBox: {
    margin: (theme) => theme.spacing(2, 0, 0),
    borderTopStyle: 'solid',
    borderTopColor: (theme) => theme.palette.secondary.main,
    borderTopWidth: 2,
    padding: (theme) => theme.spacing(4, 0, 0)
  },
  title: {
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  pageTitle: {
    padding: (theme) => theme.spacing(3, 0, 0),
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  backdrop: {
    zIndex: (theme) => theme.zIndex.drawer + 2,
  },
  overrides: {
    MuiInputBase: {
      input: {
        text: {
          color: 'white',
        }
      }
    },
  }
}

const address_fields =[ '_address1', '_city', '_state', '_postcode' ];

const AutoUpdateAddress = ( props ) => {
  const { selectedAddress, addressType } = props;
  const formikProps = useFormikContext();
  useEffect(() => {
    if(Object.keys(selectedAddress).length > 0) {
      const formatted_address = placeToAddress( selectedAddress )
      address_fields.forEach(field => {
        switch (field) {
          case '_address1':
            formikProps.setFieldValue(addressType+field, formatted_address?.street_number + ' ' + formatted_address?.street_name)
            break;
        
          case '_city':
            formikProps.setFieldValue(addressType+field, formatted_address?.city)
            break;
        
          case '_state':
            formikProps.setFieldValue(addressType+field, formatted_address?.state)
            break;
        
          case '_postcode':
            formikProps.setFieldValue(addressType+field, formatted_address?.postal_code)
            break;
        
          default:
            break;
        }        
      })
    }
  }, [ selectedAddress ])
  return null;
}

const Billing = () => {
  const zipRegEx = new RegExp('[\\d]{5}(-[\\d]{4})?');
  const phoneRegEx = new RegExp('^\\d{3}[\\s-]?\\d{3}[\\s-]?\\d{4}$');

  Yup.addMethod(Yup.string, 'optional', function(isOptional = true, defaultValue = '') {
    return this.transform(function(value) {
      if(!isOptional) return value;

      if(value && !(value === null || value === undefined || value === '')) { return value; }

      return Yup.mixed().notRequired();
    });
  });

  const AddressSchema = Yup.object().shape({
    billing_firstName: Yup.string().min(2, 'Too Short').max(50, 'Too Long').required('Required'),
    billing_lastName: Yup.string().min(2, 'Too Short').max(50, 'Too Long').required('Required'),
    billing_address1: Yup.string().min(5, 'Too Short').max(150, 'Too Long').required('Required'),
    billing_city: Yup.string().min(2, 'Too Short').max(50, 'Too Long').required('Required'),
    billing_state: Yup.string().min(2, 'Too Short').max(50, 'Too Long').required('Required'),
    billing_postcode: Yup.string().matches(zipRegEx, 'Zip Code should look like `12345`, zip+4 optional').required('Required'),
    billing_phone: Yup.string().matches(phoneRegEx, 'Phone should look something like 555-555-5555').required('Required'),
    billing_email: Yup.string().email('Must Be A Valid Email Address').required('Required'),
    shippingAddress: Yup.lazy(value => {
      if(!(value === null || value === undefined || value === '')) {
        return Yup.object().shape({
          shipping_firstName: Yup.string().min(2, 'Too Short').max(50, 'Too Long').required('Required'),
          shipping_lastName: Yup.string().min(2, 'Too Short').max(50, 'Too Long').required('Required'),
          shipping_address1: Yup.string().min(5, 'Too Short').max(150, 'Too Long').required('Required'),
          shipping_city: Yup.string().min(2, 'Too Short').max(50, 'Too Long').required('Required'),
          shipping_state: Yup.string().min(2, 'Too Short').max(50, 'Too Long').required('Required'),
          shipping_postcode: Yup.string().matches(zipRegEx, 'Zip Code should look like `12345`, zip+4 optional').required('Required'),
        });
      }
      return Yup.string().notRequired();
    })
  });

  const { setAddresses } = useContext(AppContext);
  const [ formFilled, setFormFilled ] = useState(false);
  const [ selectedBillingAddress, setSelectedBillingAddress ] = useState({});
  const [ selectedShippingAddress, setSelectedShippingAddress ] = useState({});
  const [ confirmCustomerInfo, setConfirmCustomerInfo ] = useState(false);
  const [ addressValues, setAddressValues ] = useState({});

  const handleConfirmClose = () => {
    setConfirmCustomerInfo(false)
  }

  const handleSubmitClick = (values) => {
    setAddressValues(values)
    setConfirmCustomerInfo(true)
  }
  
  const handleConfirmContinue = (values) => {
    setAddresses(addressValues);
    setFormFilled(true);
  }
    
  return (
      <Formik initialValues={ initialValues } validationSchema={ AddressSchema } onSubmit={ handleSubmitClick } >
          {({ values, handleChange, handleBlur, errors, touched, onSubmit }) => {

      return (
        
        (formFilled) ?
            <Navigate replace to="/telesales" /> :
            <Paper sx={ { ...styles.paper } }>
                <Container maxWidth="md">
                    <Typography component="h1" variant="h5" sx={ { ...styles.pageTitle } } color="inherit" gutterBottom>
                        Customer Information
                    </Typography>
                    <Form autoComplete="on">
                        <Grid container spacing={ 3 } sx={ { ...styles.grid } }>
                            <Grid item xs={ 12 }>
                                <Typography component="h3" sx={ { ...styles.title } } color="inherit" gutterBottom>
                                    Billing
                                </Typography>
                            </Grid>
                            <Grid item sm={ 6 }>
                                <TextField fullWidth={ true } error={ errors.billing_firstName && touched.billing_firstName } helperText={ (errors.billing_firstName && touched.billing_firstName) && errors.billing_firstName } sx={ { ...styles.overrides } } id="billing_firstName" variant="outlined" onChange={ handleChange } onBlur={ handleBlur } name="billing_firstName" placeholder={ addressObj.firstName } value={ values.billing_firstName } />
                            </Grid>
                            <Grid item sm={ 6 }>
                                <TextField fullWidth={ true } error={ errors.billing_lastName && touched.billing_lastName } helperText={ (errors.billing_lastName && touched.billing_lastName) && errors.billing_lastName } sx={ { ...styles.MuiInputBase } } id="billing_lastName" variant="outlined" onChange={ handleChange } onBlur={ handleBlur } name="billing_lastName" placeholder={ addressObj.lastName } value={ values.billing_lastName }  />
                            </Grid>
                            <Grid item sm={ 6 }>
                                <InputMask mask="999-999-9999" onChange={ handleChange } onBlur={ handleBlur } value={ values.billing_phone } error={ errors.billing_phone && touched.billing_phone } helperText={ (errors.billing_phone && touched.billing_phone) && errors.billing_phone }>
                                    {(inputProps) => <TextField fullWidth={ true } { ...inputProps } sx={ { ...styles.MuiInputBase } } id="billing_phone" variant="outlined"  name="billing_phone" placeholder={ addressObj.phone }  />}
                                </InputMask>
                            </Grid>
                            <Grid item sm={ 6 }>
                                <TextField fullWidth={ true } error={ errors.billing_email && touched.billing_email } helperText={ errors && errors.billing_email } sx={ { ...styles.overrides } } id="billing_email" type="email" variant="outlined" onChange={ handleChange } onBlur={ handleBlur } name="billing_email" placeholder={ addressObj.email } value={ values.billing_email }/>
                            </Grid>
                            <Grid item sm={ 12 }>
                                <Box sx={ { ...styles.addressBox } }>
                                    <GoogleAutocomplete addressFunc={ setSelectedBillingAddress } />
                                    <AutoUpdateAddress addressType="billing" selectedAddress={ selectedBillingAddress } />
                                </Box>
                            </Grid>
                            <Grid item sm={ 6 }>
                                <TextField fullWidth={ true } error={ errors.billing_address1 && touched.billing_address1 } helperText={ (errors.billing_address1 && touched.billing_address1) && errors.billing_address1 } sx={ { ...styles.overrides } } id="billing_address1" variant="outlined" autoComplete="street_address" onChange={ handleChange } onBlur={ handleBlur } name="billing_address1" placeholder={ addressObj.address1 } value={ values.billing_address1 } />
                            </Grid>
                            <Grid item sm={ 6 }>
                                <TextField fullWidth={ true } sx={ { ...styles.MuiInputBase } } id="billing_address2" variant="outlined" onChange={ handleChange } onBlur={ handleBlur } name="billing_address2" placeholder={ addressObj.address2 } value={ values.billing_address2 } />
                            </Grid>
                            <Grid item sm={ 6 }>
                                <TextField fullWidth={ true } error={ errors.billing_city && touched.billing_city } helperText={ (errors.billing_city && touched.billing_city) && errors.billing_city } sx={ { ...styles.overrides } } id="billing_city" variant="outlined" onChange={ handleChange } onBlur={ handleBlur } name="billing_city" placeholder={ addressObj.city } value={ values.billing_city } />
                            </Grid>
                            <Grid item sm={ 6 }>
                                <StateSelect name="billing_state" handleBlur={ handleBlur } handleChange={ handleChange } placeholder={ addressObj.state } value={ values.billing_state } error={ errors.billing_state } touched={ touched.billing_state } label="state" />
                            </Grid>
                            <Grid item sm={ 6 }>
                                <TextField fullWidth={ true } error={ errors.billing_postcode && touched.billing_postcode } helperText={ (errors.billing_postcode && touched.billing_postcode) && errors.billing_postcode } sx={ { ...styles.overrides } } id="billing_postcode" variant="outlined" onChange={ handleChange } onBlur={ handleBlur } name="billing_postcode" placeholder={ addressObj.postcode } value={ values.billing_postcode } />
                            </Grid>
                            <Grid item sm={ 6 }>
                            </Grid>
                            <Grid item xs={ 12 }>
                                <Typography component="h3" sx={ { ...styles.title } } color="inherit" gutterBottom>
                                    Shipping
                                </Typography>
                            </Grid>
                            <Grid item xs={ 12 }>
                                <FormControlLabel
                  control={ <Switch name="shippingChecked" checked={ values.shippingChecked } /> }
                  label="Same As Billing"
                  onChange={ handleChange }
                  onBlur={ handleBlur }
                />
                            </Grid>
                            { !values.shippingChecked && (
                            <Fragment>
                                <Grid item sm={ 6 }>
                                    <TextField fullWidth={ true } error={ errors.shipping_firstName && touched.shipping_firstName } helperText={ (errors.shipping_firstName && touched.shipping_firstName) && errors.shipping_firstName } sx={ { ...styles.overrides } } id="shipping_firstName" variant="outlined" onChange={ handleChange } onBlur={ handleBlur } name="shipping_firstName" placeholder={ addressObj.firstName } value={ values.shipping_firstName } />
                                </Grid>
                                <Grid item sm={ 6 }>
                                    <TextField fullWidth={ true } error={ errors.shipping_lastName && touched.shipping_lastName } helperText={ (errors.shipping_lastName && touched.shipping_lastName) && errors.shipping_lastName } sx={ { ...styles.MuiInputBase } } id="shipping_lastName" variant="outlined" onChange={ handleChange } onBlur={ handleBlur } name="shipping_lastName" placeholder={ addressObj.lastName } value={ values.shipping_lastName } />
                                </Grid>
                                <Grid item sm={ 12 }>
                                    <Box sx={ { ...styles.addressBox } }>
                                        <GoogleAutocomplete addressFunc={ setSelectedShippingAddress } />
                                        <AutoUpdateAddress addressType="shipping" selectedAddress={ selectedShippingAddress } />
                                    </Box>
                                </Grid>
                                <Grid item sm={ 6 }>
                                    <TextField fullWidth={ true } error={ errors.shipping_address1 && touched.shipping_address1 } helperText={ (errors.shipping_address1 && touched.shipping_address1) && errors.shipping_address1 } sx={ { ...styles.overrides } } id="shipping_address1" variant="outlined" onChange={ handleChange } onBlur={ handleBlur } name="shipping_address1" placeholder={ addressObj.address1 } value={ values.shipping_address1 } />
                                </Grid>
                                <Grid item sm={ 6 }>
                                    <TextField fullWidth={ true } sx={ { ...styles.MuiInputBase } } id="shipping_address2" variant="outlined" onChange={ handleChange } onBlur={ handleBlur } name="shipping_address2" placeholder={ addressObj.address2 } value={ values.shipping_address2 } />
                                </Grid>
                                <Grid item sm={ 6 }>
                                    <TextField fullWidth={ true } error={ errors.shipping_city && touched.shipping_city } helperText={ (errors.shipping_city && touched.shipping_city) && errors.shipping_city } sx={ { ...styles.overrides } } id="shipping_city" variant="outlined" onChange={ handleChange } onBlur={ handleBlur } name="shipping_city" placeholder={ addressObj.city } value={ values.shipping_city } />
                                </Grid>
                                <Grid item sm={ 6 }>
                                    <StateSelect name="shipping_state" handleBlur={ handleBlur } handleChange={ handleChange } placeholder={ addressObj.state } value={ values.shipping_state } error={ errors.shipping_state } touched={ touched.shipping_state } label="state" />
                                </Grid>
                                <Grid item sm={ 6 }>
                                    <TextField fullWidth={ true } error={ errors.shipping_postcode && touched.shipping_postcode } helperText={ (errors.shipping_postcode && touched.shipping_postcode) && errors.shipping_postcode } sx={ { ...styles.overrides } } id="shipping_postcode" variant="outlined" onChange={ handleChange } onBlur={ handleBlur } name="shipping_postcode" placeholder={ addressObj.postcode } value={ values.shipping_postcode } />
                                </Grid>
                            </Fragment>
              )}
                            <Grid item xs={ 12 }>
                                <Button sx={ { ...styles.button } } variant="contained" type="submit" onSubmit={ onSubmit }>Create Order</Button>
                            </Grid>
                        </Grid>
                    </Form>
                </Container>
                <Dialog
              open={ confirmCustomerInfo }
              onClose={ handleConfirmClose }
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
                    <DialogTitle id="alert-dialog-title">Please Confirm All Customer Information</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Please verify all customer information is correct before proceeding to the Affirm Submission page.
                            Once this information is submitted orders will be created and any adjustments will need to be made by creating new orders.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={ handleConfirmClose } color="primary">Cancel</Button>
                        <Button onClick={ handleConfirmContinue } color="secondary" autoFocus>Continue</Button>
                    </DialogActions>
                </Dialog>
            </Paper>
      )}}
      </Formik>
  );
}

export default Billing;