import { gql } from '@apollo/client';

/**
 * GraphQL Retail Sales Locations query.
 */
const LOCATIONS_QUERY = gql`
	query LocationsQuery {
	  tonalSalesLocations {
			key
			label
	  }
}`;

export default LOCATIONS_QUERY;
