import React, { useEffect, useState, useMemo, Fragment, useContext } from 'react';
import { BottomNavigation, Box } from '@mui/material';
import { BottomNavigationAction } from '@mui/material';
import { Table } from '@mui/material';
import { TableBody } from '@mui/material';
import { TableCell } from '@mui/material';
import { TableContainer } from '@mui/material';
import { TableHead } from '@mui/material';
import { TableRow } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Paper } from '@mui/material';
import { Link } from '@mui/material';
import { Backdrop } from '@mui/material';
import { CircularProgress } from '@mui/material';
import { Edit } from '@mui/icons-material';
import { ArrowBackIos } from '@mui/icons-material';
import { ArrowForwardIos } from '@mui/icons-material';
import { TextField } from '@mui/material';
import { Search } from '@mui/icons-material';

import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { AppContext } from '../context/AppContext';
import SALES_ORDERS from '../queries/sales-orders';
import { NetworkStatus, useQuery, useLazyQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/use-auth'
import { updateQuery } from '../functions/order';
import { cloneDeep, set } from 'lodash';

const styles = {
  table: {
    minWidth: 360,
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: (theme) => theme.spacing(2),
    flex: 1,
  },
  dialogContent: {
    display: 'flex',
    justifyContent: 'center',
  },
  dialogContentChild: {
    maxWidth: 600,
  },
  circularProgress: {
    color: (theme) => theme.palette.secondary.main,
    width:  '50px',
    height: '50px',
  },
  backdrop: {
    zIndex: (theme) => theme.zIndex.drawer + 3,
  },
  activeButton: {
    color: (theme) => theme.palette.secondary.main,
  },
  disabledButton: {
    color: (theme) => theme.palette.main,
  },
  bottomNavigation: {
    justifyContent: 'flex-end',
    paddingLeft: (theme) => theme.spacing(2),
    paddingRight: (theme) => theme.spacing(2),
  },
}

const Orders = () => {
  const auth = useAuth()
  const [ orders, setOrders ] = useState([]);
  const [ isFetchingMore, setIsFetchingMore ] = useState(false)
  const [ tableLoading, setTableLoading ] = useState(false)
  const [ pageInfo, setPageInfo ] = useState(null)
  const [ toggleState, setToggleState ] = useState('myOrders')
  const [ searchValue, setSearchValue ] = useState()
  const [ sendSearch, setSendSearch ] = useState(false)

  const baseQuery = {
    first: 10,
    last: null,
    startCursor: null,
    endCursor: null,
    where: {
      statuses: 'PENDING',
      createdVia: 'graphql-api',
    },
  }

  const myOrderVars = cloneDeep(baseQuery)
  set(myOrderVars, 'where.telesalesAgent', auth.user.login.user.databaseId)

  const otherOrderVars = cloneDeep(baseQuery)
  set(otherOrderVars, 'where.telesalesAgent', auth.user.login.user.databaseId * -1)

  const { setRequestError, setOrder } = useContext(AppContext)
  const navigate = useNavigate();

  const { loading: loadingOrders, fetchMore: fetchMoreOrders, refetch: refetchOrders, networkStatus: networkStatusOrders } = useQuery(SALES_ORDERS, {
    variables: { ...myOrderVars },
    options: {
      notifyOnNetworkStatusChange: true,
    },
    nextFetchPolicy: 'cache-first',
    onCompleted: (results => {
      const fetchedOrders = results.orders.edges.map(item => item.node)
      setOrders( fetchedOrders )
      setPageInfo( results.orders.pageInfo )
    }),
    onError: (error => {
      setRequestError(error)
      console.error(error)
    }),    
  })

  const [ getOSO, { loading: loadingOSO, fetchMore: fetchMoreOSO, networkStatus: networkStatusOSO, called: calledOSO } ] = useLazyQuery(SALES_ORDERS, {
    options: {
      notifyOnNetworkStatusChange: true,
      nextFetchPolicy: 'cache-first',
    },
  })

  const [ getSearch, { loading: loadingSearch, fetchMore: fetchMoreSearch, networkStatus: networkStatusSearch } ] = useLazyQuery(SALES_ORDERS)

  useEffect(() => {
    setTableLoading(
      loadingSearch ||
      loadingOrders ||
      loadingOSO ||
      isFetchingMore ||
      networkStatusOrders === NetworkStatus.fetchMore ||
      networkStatusOrders === NetworkStatus.refetch ||
      networkStatusOSO === NetworkStatus.fetchMore ||
      networkStatusOSO === NetworkStatus.refetch ||
      networkStatusSearch === NetworkStatus.fetchMore ||
      networkStatusSearch === NetworkStatus.refetch
    )
  }, [ loadingSearch, networkStatusOrders, loadingOrders, loadingOSO, networkStatusOSO, calledOSO, isFetchingMore ])

  const onNavClick = (event, data) => {
    let newPageCursor = {}

    if ( data.direction === 'more' ) {
      newPageCursor = {
        first: 10,
        after: pageInfo.endCursor,
        last: null,
        before: null,
      }
    } else {
      newPageCursor = {
        first: null,
        after: null,
        last: 10,
        before: pageInfo.startCursor,
      }
    }

    switch(toggleState) {
      case 'salesOrders':
        setIsFetchingMore( true )
        fetchMoreOSO( { 
          variables: { ...newPageCursor },
       }, updateQuery )
        .then((results) => {
          const fetchedOrders = results.data.orders.edges.map(item => item.node)
          setOrders( fetchedOrders )
          setPageInfo( results.data.orders.pageInfo )
        }).finally(() => setIsFetchingMore( false ))
        break

      case 'searchOrders':
        setIsFetchingMore( true )
        fetchMoreSearch( { variables: { ...newPageCursor } }, updateQuery )
        .then((results) => {
          const fetchedOrders = results.data.orders.edges.map(item => item.node)
          setOrders( fetchedOrders )
          setPageInfo( results.data.orders.pageInfo )
        }).finally(() => setIsFetchingMore( false ))
        break

      case 'myOrders':
        setIsFetchingMore( true )
        fetchMoreOrders( { variables: { ...newPageCursor } }, updateQuery )
        .then((results) => {
          const fetchedOrders = results.data.orders.edges.map(item => item.node)
          setOrders( fetchedOrders )
          setPageInfo( results.data.orders.pageInfo )
        }).finally(() => setIsFetchingMore( false ))
        break

      default:
        break
    }
  }

  const onToggleChange = (event, newToggle) => {
    setToggleState(newToggle)
  }

  useMemo(() => {
      switch (toggleState) {
        case 'salesOrders': {
          getOSO({
            variables: { ...otherOrderVars },
            options: {
              notifyOnNetworkStatusChange: true,
            },
            nextFetchPolicy: 'cache-first',
            onCompleted: (results => {
              const fetchedOrders = results.orders.edges.map(item => item.node)
              setOrders( fetchedOrders )
              setPageInfo( results.orders.pageInfo )
            }),
            onError: (error => {
              setRequestError(error)
              console.error(error)
            }),    
          })
          break;
        }
          
        case 'searchOrders': {
          setSendSearch(true)
          break;
        }
            
        case 'myOrders':
          refetchOrders()
          .then((results) => {
            const fetchedOrders = results.data.orders.edges.map(item => item.node)
            setOrders( fetchedOrders )
            setPageInfo( results.data.orders.pageInfo )
          })
          break;
            
        default:
          break;
      }
  }, [ toggleState ])

  useMemo(() => {
    if (sendSearch) {
      const searchQuery = cloneDeep(baseQuery)
      set(searchQuery, 'where.search', searchValue || '')
      
      getSearch({
        variables: { ...searchQuery },
        options: {
          notifyOnNetworkStatusChange: true,
        },
        fetchPolicy: 'network-only',
        onCompleted: (results => {
          const fetchedOrders = results.orders.edges.map(item => item.node)
          setOrders( fetchedOrders )
          setPageInfo( results.orders.pageInfo )
          setSendSearch(false)
        }),
        onError: (error => {
          setRequestError(error)
          console.error(error)
          setSendSearch(false)
        })
      })
    }
  }, [ sendSearch ])

  const editOrder = ( row ) => {
    const order = row.original
    setOrder( order )
    navigate( `/order/${ order.databaseId }`, { replace: true } )
  }

  const columnHelper = createColumnHelper()

  const columns = [
    {
      accessorKey: 'databaseId',
      header: 'Order',
    },
    {
      accessorKey: 'status',
      header: 'Status',
    },
    {
      accessorKey: 'telesalesAgent',
      header: 'Sales Agent (*Showroom)',
      accessorFn: (row) => {
        let email = row.salesAttributes.telesalesAgent ? row.salesAttributes.telesalesAgent.email : ''
        if ( ! email ) {
          email = `${ row.salesAttributes.showroomSalesAgent }*` ?? ''
        }
        return email
      },
    },
    {
      accessorKey: 'date',
      header: 'Date',
      cell: ( props ) => {
        const date = new Date(props.getValue());
        const dateFormat = new Intl.DateTimeFormat('en', {
          timeStyle: 'medium',
          dateStyle: 'short',
        });
        return dateFormat.format(date);
      }
    },
    {
      accessorKey: 'billing.firstName',
      header: 'First Name',
    },
    {
      accessorKey: 'billing.lastName',
      header: 'Last Name',
    },
    {
      accessorKey: 'billing.email',
      header: 'Email',
    },
    {
      accessorKey: 'billing.phone',
      header: 'Phone',
    },
    {
      accessorKey: 'total',
      header: 'Total',
    },
    columnHelper.display({
        id: 'edit',
        header: () => ( <div>Edit</div> ),
        cell: ( props ) => (
            <Link onClick={ () => { editOrder(props.row) } }>
                <Edit />
            </Link>
        ),
      },
    )
  ]

  return (
      <OrderTable
        columns={ columns }
        data={ orders } 
        pageInfo={ pageInfo } 
        toggleState={ toggleState } 
        onNavClick={ onNavClick } 
        onToggleChange={ onToggleChange }
        setSearchValue={ setSearchValue }
        searchValue={ searchValue }
        loadingValue={ tableLoading }
        setSendSearch={ setSendSearch }
      />
  )
}

const OrderTable = ( { columns, data, pageInfo, onNavClick, onToggleChange, toggleState, searchValue, setSearchValue, loadingValue, setSendSearch } ) => {
  const [ paginationValue, setPaginationValue ] = useState(null)

  const table = useReactTable({
    data,
    columns, 
    getCoreRowModel: getCoreRowModel()
  })

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      setSendSearch(true)
    }
  }

  return (
      <Fragment>
          <Backdrop open={ loadingValue } sx={ { ...styles.backdrop } }>
              <CircularProgress color="inherit" sx={ { ...styles.circularProgress } } />
          </Backdrop>
          <Box padding={ theme => theme.spacing(2) } sx={ { display: 'flex' } } justifyContent='space-between'>                      
              <ToggleButtonGroup
                color='secondary'
                size='small'
                aria-label="small button group"
                exclusive
                value={ toggleState }
                onChange={ onToggleChange }>
                  <ToggleButton value="myOrders">My Orders</ToggleButton>
                  <ToggleButton value="salesOrders">Other Sales Orders</ToggleButton>
                  <form>
                      <TextField
                        variant="outlined"
                        size="small"
                        value={ searchValue || '' }
                        onChange={ (e) => setSearchValue(e.target.value) }
                        onKeyDown={ handleKeyDown }
                        label="Search"
                      />
                  </form>
                  <ToggleButton value="searchOrders">
                      <Search/>
                  </ToggleButton>
              </ToggleButtonGroup>
              <Box>
              </Box>
          </Box>
          <TableContainer component={ Paper }>
              <Table sx={ { ...styles.table } } size="small">
                  <TableHead>
                      {
                        table.getHeaderGroups().map(headerGroup => (
                            <TableRow key={ headerGroup.id }>
                                {
                                  headerGroup.headers.map(header => (
                                      <TableCell key={ header.id }>
                                          {header.isPlaceholder
                                            ? null
                                            : flexRender(header.column.columnDef.header, header.getContext())
                                          }
                                      </TableCell>
                                  ))
                                }                         
                            </TableRow>
                        ))
                      }
                  </TableHead>
                  <TableBody>
                      {
                        table.getRowModel().rows.map(row => {
                            return (
                                <TableRow key={ row.id }>
                                    {
                                      row.getVisibleCells().map(cell => {
                                          return (
                                              <TableCell key={ cell.id }>
                                                  { flexRender(cell.column.columnDef.cell, cell.getContext() ) }
                                              </TableCell>
                                          )
                                      }) 
                                    }
                                </TableRow>
                        )})
                      }
                  </TableBody>
              </Table>
          </TableContainer>
          {
            pageInfo &&
            <BottomNavigation
              value={ paginationValue }
              onChange={ onNavClick }
              sx={ { ...styles.bottomNavigation } }
              showLabels
            >
                <BottomNavigationAction label="Back" value={ { direction: 'previous' } } disabled={ !pageInfo.hasPreviousPage } icon={ <ArrowBackIos/> } sx={ {
                  ...( pageInfo.hasPreviousPage && styles.activeButton ),
                  ...( ! pageInfo.hasPreviousPage && styles.disabledButton )
                } }  />
                <BottomNavigationAction label="More" value={ { direction: 'more' } } disabled={ !pageInfo.hasNextPage } icon={ <ArrowForwardIos/> } sx={ {
                  ...( pageInfo.hasNextPage && styles.activeButton ),
                  ...( ! pageInfo.hasNextPage && styles.disabledButton)
                } } />
            </BottomNavigation>
          }
      </Fragment>
  )
}

export default Orders