import React from 'react';
import { Button } from '@mui/material';

const styles = {
  start: {
    margin: (theme) => theme.spacing(3, 0, 2),
    textTransform: 'uppercase',
    backgroundColor: '#de30a4',
    color: (theme) => theme.palette.primary.main,
    fontSize: '1rem',
    padding: (theme) => theme.spacing(2),
  },
}

const NewOrderButton = ( props ) => {
  const { handleNewOrder } = props;

  return (
      <Button sx={ { ...styles.start } } variant="contained" type="submit" onClick={ handleNewOrder }>Start New Order</Button>
  ) 
}

export default NewOrderButton