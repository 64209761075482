import React, { useState, useContext, useEffect } from 'react';
import { Typography } from '@mui/material';

import { AppContext } from '../context/AppContext';

const styles = () => ({
  name: {
    fontSize: '1rem',
    fontWeight: 500,
  },
});

const AddressFieldView = ( { addressType } ) => {
  const { order } = useContext(AppContext);
  const [ field, setField ] = useState(null)

  useEffect(() => {
    setField( order[ addressType ] )
  }, [ order ])

  return (
      <>
          {
            field ?
                <Typography variant="body2" component="p">
                    <span sx={ { ...styles.name } }>{field.firstName} {field.lastName}</span><br/>
                    {(field.address1) ? <>{field.address1} <br/></> : ''}
                    {field?.address2}
                    {field.address2 ? <br/> : '' }
                    {(field.city) ? field.city + ',' : ''} {field.state} {(field.postcode) ? <>{field.postcode} <br/></> : ''}
                    {(field.email) ? field.email : ''}
                    {(field.email) ? <br/> : ''}
                    {(field.phone) ? field.phone : ''}
                </Typography>
          : ''
          }
      </>
  )  
}

export default AddressFieldView;