import React from 'react';
import { Container } from '@mui/material';
import { Box } from '@mui/material';
import { Grid } from '@mui/material';
import { CssBaseline } from '@mui/material';
import { Outlet } from 'react-router-dom';
import Navbar from './navbar';
import Footer from './footer';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: '100vh',
  },
  main: {
    marginTop: (theme) => theme.spacing(2),
    marginBottom: (theme) => theme.spacing(2),
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}

const Layout = () => {

  return (
      <Container disableGutters={ true } maxWidth={ false } sx={ { ...styles.root } }>
          <Box>
              <CssBaseline/>
              <Navbar/>
              <Container component="main" sx={ { ...styles.main } } maxWidth="lg">
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    my={ 4 }
                  >
                      <Grid item xs={ 12 }>
                          <Outlet />
                      </Grid>
                  </Grid>
              </Container>
              <Footer/>
          </Box>
      </Container>
  );
}

export default Layout;