import { Box } from '@mui/material'
import { Link } from '@mui/material'
import { Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import bench from '../assets/img/Bench.jpg';

const styles = {
  root: {
    height: '75vh',
    position: 'relative',
  },
  container: {
    position: 'relative',
    height: '100%',
    zIndex: 2,
  },
  hero: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  figure: {
    position: 'absolute',
    zIndex: 1,
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    opacity: '0.15',
    margin: 0,
    display: 'flex',
  },
  image: {
    objectFit: 'cover',
    maxWidth: '100%',
    height: 'auto',
  },
  link: {
    backgroundColor: (theme) => theme.palette.primary.main,
    padding: (theme) => theme.spacing(3),
    margin: (theme) => theme.spacing(3),
    textDecoration: 'none',
    textTransform: 'uppercase',
    fontWeight: 700,
    textAlign: 'center',
    borderRadius: '3px',
  },
  title: {
    fontWeight: 700,
    flexGrow: 2,
    flexBasis: '100%',
    textAlign: 'center',
  },
}

const Home = () => {

  return (
      <Box component="div" sx={ { ...styles.root } }>
          <Box component="div" sx={ { ...styles.container } }>
              <Box component="div" sx={ { ...styles.hero } }>
                  <Typography component="h1" sx={ { ...styles.title } } variant="h3" color="inherit" gutterBottom>
                      Let's Get Started
                  </Typography>
                  <Link sx={ { ...styles.link } } underline='none' component={ RouterLink } to="/order/0">
                      Start a new order
                  </Link>
                  <Link sx={ { ...styles.link } } underline='none' component={ RouterLink } to="/orders">
                      Manage orders
                  </Link>
              </Box>
          </Box>
          <Box component="figure" sx={ { ...styles.figure } }>
              <Box component="img" sx={ { ...styles.image } } src={ bench } alt="" />
          </Box>
      </Box>
  )
}

export default Home
