import React, { useEffect, useState } from 'react';
import { Table, Typography } from '@mui/material';
import { TableBody } from '@mui/material';
import { TableCell } from '@mui/material';
import { TableContainer } from '@mui/material';
import { TableHead } from '@mui/material';
import { TableRow } from '@mui/material';
import { Paper } from '@mui/material';
import { Button, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText, TextField } from '@mui/material';
import { List } from '@mui/material'
import { ListItem } from '@mui/material';
import { ListItemText } from '@mui/material';
import { CircularProgress } from '@mui/material';
import parse from 'html-react-parser';
import currency from 'currency.js';

const styles = {
  table: {
    minWidth: 350,
  },
  total: {
    fontWeight: 700,
    fontSize: '1.5rem',
  },
  cancelButton: {
    color: (theme) => theme.palette.primary.light
  },
  listItem: {
    padding: 0,
    textAlign: 'right',
  },
  wrapper: {
    margin: (theme) => theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: (theme) => theme.palette.secondary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}

function priceRow(qty, unit) {
  return qty * unit;
}

function createRow(desc, qty, unit) {
  const price = priceRow(qty, unit);
  return { desc, qty, unit, price };
}

function subtotal(items) {
  return items.map(({ price }) => price).reduce((sum, i) => sum + i, 0);
}

function ccyFormat(num) {
  const formatNum = num ? num : 0;
  return currency(formatNum, {
    precision: 2,
  }).format()
}

function calculateTaxes(taxLines) {
  const reducer = (accumulator, currentItem) => currency(accumulator).value + ( currency(currentItem.taxTotal).add(currentItem.shippingTaxTotal).value );
  return taxLines.reduce(reducer, 0);
}

function calculateOrderTotal(orderTotal = 0, taxTotal, taxLines) {
  const calculatedTaxes = calculateTaxes(taxTotal, taxLines);
  return currency(orderTotal).add(calculatedTaxes).value;
}

function calculatedShipping(shippingLines) {
  const reducer = (accumulator, currentLine) => currency(accumulator).add( currentLine.total ).value
  const shippingTotal = shippingLines.reduce(reducer, 0)
  return ccyFormat(shippingTotal)
}

function processShippingTotal( order ) {
  if (0 < order?.shippingLines?.nodes.length) {
    return calculatedShipping(order.shippingLines.nodes)
  } else {
    return (order.shippingTotal) ? ccyFormat(order.shippingTotal) : ''
  }
}

function PriceTable( props ) {

  const { order, handleDiscount, handleDialog, handleCloseDialog, loadingCoupon, dialogOpen } = props;

  const [ rows, setRows ] = useState([])
  const [ orderSubtotal, setOrderSubtotal ] = useState(null)

  useEffect(() => {
    if(order.databaseId) {
      const allLineItems = order.lineItems.nodes.map((item) => {
        return createRow(item.product.node.name, item.quantity, currency(item.product.node.price))
      })

      setRows(allLineItems)
    }
  }, [ order ])

  useEffect(() => {
    setOrderSubtotal(subtotal(rows))
  }, [ rows ])
  
  return (
      <>
          { order.databaseId ?
              <TableContainer component={ Paper }>
                  <Table sx={ { ...styles.table } } aria-label="spanning table">
                      <TableHead>
                          <TableRow>
                              <TableCell align="left" colSpan={ 2 }>Product</TableCell>
                              <TableCell align="right">Price</TableCell>
                          </TableRow>
                      </TableHead>
                      <TableBody>
                          {rows.map((row) => (
                              <TableRow key={ row.desc }>
                                  <TableCell>{parse(row.desc)}</TableCell>
                                  <TableCell align="right">{row.qty} <Typography variant='overline'>(qty)</Typography></TableCell>
                                  <TableCell align="right">{ccyFormat(row.price)}</TableCell>
                              </TableRow>
                  ))}
                          <TableRow>
                              <TableCell colSpan={ 2 } align="right">Subtotal</TableCell>
                              <TableCell align="right">{ccyFormat(orderSubtotal)}</TableCell>
                          </TableRow>
                          <TableRow>
                              <TableCell align="right">
                                  <Button variant="outlined" color="secondary" data-order-id={ order.databaseId } onClick={ handleDialog } disabled={ loadingCoupon }>Add Discount</Button>
                                  <Dialog open={ dialogOpen } className="dialog" data-order-id={ order.databaseId } onClose={ handleCloseDialog } aria-labelledby={ `form-dialog-title-${ order.databaseId }` }>
                                      <DialogTitle id={ `form-dialog-title-${ order.databaseId }` }>Enter Discount</DialogTitle>
                                      <DialogContent>
                                          <DialogContentText>
                                              If entering more than one code separate with a comma.
                                          </DialogContentText>
                                          <TextField 
                                            autoFocus
                                            id={ `discount-${ order.databaseId }` }
                                            label="Discount"
                                            type="text"
                                            name="discount-field"
                                            fullWidth={ true }
                                          />
                                      </DialogContent>
                                      <DialogActions>
                                          <Button onClick={ handleDialog } data-order-id={ order.databaseId } sx={ { ...styles.cancelButton } }>Cancel</Button>
                                          <div sx={ { ...styles.wrapper } }>
                                              <Button onClick={ handleDiscount } disabled={ loadingCoupon } data-order-id={ order.databaseId } color="secondary">Submit</Button>
                                              { loadingCoupon && <CircularProgress size={ 24 } sx={ { ...styles.buttonProgress } } /> }
                                          </div>
                                      </DialogActions>
                                  </Dialog>
                              </TableCell>
                              <TableCell align="right">Discounts</TableCell>
                              {
                      (order.couponLines) ? 
                          <TableCell align="right">
                              <List dense={ true }>
                                  {
                            order.couponLines.nodes.map( (couponLine) => (
                                <ListItem key={ `couponItem-${ couponLine.code }` } sx={ { ...styles.listItem } }>
                                    <ListItemText
                                  primary={ `-${ ccyFormat(couponLine.discount) }` } 
                                  secondary={ couponLine.code }
                                  title={ couponLine.coupon.description }
                                />
                                </ListItem>
                              ) )
                          }
                              </List>
                          </TableCell>
                      : 
                          <React.Fragment>
                              <TableCell align="left"></TableCell>
                          </React.Fragment>
                    }
                          </TableRow>
                          <TableRow>
                              <TableCell colSpan={ 2 } align="right">Shipping</TableCell>
                              <TableCell align="right">{ processShippingTotal(order)}</TableCell>
                          </TableRow>
                          <TableRow>
                              <TableCell colSpan={ 2 } align="right">Tax</TableCell>
                              <TableCell align="right">{ order.totalTax ? ccyFormat(order.totalTax) : '' }</TableCell>
                          </TableRow>
                          <TableRow>
                              <TableCell colSpan={ 2 } align="right">Total</TableCell>
                              <TableCell align="right" sx={ { ...styles.total } }>{ order.total ? ccyFormat(order.total) : '' }</TableCell>
                          </TableRow>
                      </TableBody>
                  </Table>
              </TableContainer>
              : ''
              }
      </>
  )
}

export default PriceTable;