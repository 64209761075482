import { gql } from '@apollo/client';
import { ORDER_FIELDS } from '../fragments/order';

const CREATE_ORDER = gql`
  ${ ORDER_FIELDS }
  mutation ($input: CreateOrderInput!) {
    createOrder(input: $input) {
      order {
        ...OrderFields
      }
    }
  }
`;

export default CREATE_ORDER;
