import React, { useState, useEffect } from 'react'
import packageJson from '../package.json'

global.appVersion = packageJson.version

const semverGreaterThan = (versionA, versionB) => {
    const versionsA = versionA.split(/\./g)
    const versionsB = versionB.split(/\./g)

    while (versionsA.length || versionsB.length) {
        const a = Number(versionsA.shift())
        const b = Number(versionsB.shift())

        if (a === b) continue

        return a > b || isNaN(b)
    }
    return false
}

const CacheBuster = (props) => {
    const [ loading, setLoading ] = useState(true)
    const [ isLatestVersion, setIsLatestVersion ] = useState(true)
    const refreshCacheAndReload = () => {
        sessionStorage.clear();
        window.location.reload(true)
    }

    useEffect(() => {
        fetch('/meta.json')
        .then((response) => response.json())
        .then((meta) => {
            const latestVersion = meta.version
            const currentVersion = global.appVersion

            const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion)
            setLoading(false)
            setIsLatestVersion(!shouldForceRefresh)
        })
        .catch(console.error)
    }, [])

    useEffect(() => {
        if (!isLatestVersion) {
            refreshCacheAndReload()
        }
    }, [ isLatestVersion ])

    return (
        !loading && isLatestVersion && <>
            {props.children}
        </>
    )
}

export default CacheBuster