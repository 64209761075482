import { gql } from '@apollo/client';

const LOGOUT_USER = gql`
  mutation LOGOUT_MUTATION($input: LogoutInput!) {
    logout( input: $input ) 
    {
      status
      clientMutationId
    }
  }
`;

export default LOGOUT_USER;