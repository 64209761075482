import { gql } from '@apollo/client';

const LOGIN_USER = gql`
  mutation LOGIN_MUTATION($input: LoginInput!) {
    login( input: $input ) {
      authToken
      sessionToken
      user {
        databaseId
        name
      }
    }
  }
`;

export default LOGIN_USER;