/**
 * Extracts and returns a formatted object from a google place object.
 *
 * @param {object} object Object
 * @return {object}
 */
 export const placeToAddress = ( place ) => {
  const address = {};
  place.address_components.forEach((c) => {
    switch (c.types[ 0 ]) {
      case 'street_number':
        address.street_number = c.long_name
        break;
    
      case 'route':
        address.street_name = c.long_name
        break;
    
      case 'locality':
        address.city = c.long_name
        break;
    
      case 'administrative_area_level_1':
        address.state = c.short_name
        break;
    
      case 'country':
        address.country = c.short_name
        break;
    
      case 'postal_code':
        address.postal_code = c.long_name
        break;
    
      default:
        break;
    }
  })
  
	return address;
};
